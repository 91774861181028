import React from "react";
import TBNotified from "../components/Charts/TBNotified2";
import TBReporting from "../components/Charts/TBReporting2";
import TBoutcome from "../components/Charts/TBoutcome2";

function APICharts() {
  return (
    <div id="chart-column" className="col-md-12 p-5">
      <TBNotified
        params={{
          date: 207708,
        }}
      />
      <TBReporting params={{}} />
      <TBoutcome params={{}} />
    </div>
  );
}

export default APICharts;
