import React, { useEffect } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../../constants";
import {
  setDistrict,
  setLocalLevel,
  setProvince,
  setDistrictIds,
  setLocalLevelIds,
  setProvinceIds,
  setProvinceList,
  setDistrictList,
  setLocalLevelList,
  setFiscalYears,
} from "../../slices/adminvalues";
function AdminSelectBar() {
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  useEffect(() => {
    fetch(BASE_URL + "province/").then((res) => {
      res.json().then((result) => {
        const idsList = result.map((e) => e.id);
        dispatch(setProvinceList(result));
        dispatch(setProvinceIds(idsList));
      });
    });
    fetch(BASE_URL + "fiscalyears/").then((res) => {
      res.json().then((result) => dispatch(setFiscalYears(result)));
    });
    fetch(BASE_URL + `district/`).then((res) => {
      res.json().then((result) => dispatch(setDistrictList(result)));
    });
    fetch(BASE_URL + `locallevel/`).then((res) => {
      res.json().then((result) => dispatch(setLocalLevelList(result)));
    });
    return () => {};
  }, [dispatch]);
  function handleProvinceChange(values) {
    const idsList = values.map((e) => e.value);
    dispatch(setProvinceIds(idsList));
    if (idsList.length) {
      dispatch(setProvince(idsList.at(-1)));
    } else {
      dispatch(setProvince(null));
    }
  }
  function handleDistrictChange(values) {
    const idsList = values.map((e) => e.value);
    dispatch(setDistrictIds(idsList));
    if (idsList.length) {
      dispatch(setDistrict(idsList.at(-1)));
    } else {
      dispatch(setDistrict(null));
    }
  }
  function handleLocalLevelChange(values) {
    const idsList = values.map((e) => e.value);
    if (idsList.length) {
      dispatch(setLocalLevel(idsList.at(-1)));
    } else {
      dispatch(setLocalLevel(null));
    }
    dispatch(setLocalLevelIds(idsList));
  }
  return (
    <div className="row gy-0 gx-0">
      <div className="col-md-6">
        <Select
          placeholder="Select Province"
          isMulti
          value={admin.provinces
            .map((e) => {
              return { value: e.id, label: e.name_en };
            })
            .filter((e) => admin.adminFilter.province_ids.includes(e.value))}
          onChange={handleProvinceChange}
          options={admin.provinces.map((e) => {
            return { value: e.id, label: e.name_en };
          })}
        />
      </div>
      <div className="col-md-3">
        <Select
          placeholder="Select District"
          isMulti
          value={admin.districts
            .map((e) => {
              return { value: e.id, label: e.name_en };
            })
            .filter((e) => admin.adminFilter.district_ids.includes(e.value))}
          onChange={handleDistrictChange}
          options={admin.districts
            .filter((e) =>
              admin.adminFilter.province_ids.includes(e.province_id)
            )
            .map((e) => {
              return { value: e.id, label: e.name_en };
            })}
        />
      </div>
      <div className="col-md-3">
        <Select
          placeholder="Select Local Level"
          isMulti
          value={admin.local_levels
            .map((e) => {
              return { value: e.id, label: e.name_en };
            })
            .filter((e) => admin.adminFilter.local_level_ids.includes(e.value))}
          onChange={handleLocalLevelChange}
          options={admin.local_levels
            .filter((e) =>
              admin.adminFilter.district_ids.includes(e.district_id)
            )
            .map((e) => {
              return { value: e.id, label: e.name_en };
            })}
        />
      </div>
    </div>
  );
}

export default AdminSelectBar;
