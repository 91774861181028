import React from "react";
import { useSelector } from "react-redux";
import HFDataPie from "../components/Charts/HFDataPie";

function SDPPieChart() {
  const layerVisibility = useSelector((state) => state.layer);
  const filter = useSelector((state) => state.admin.adminFilter);
  return (
    <div id="chart-column" className="p-5">
      <HFDataPie
        params={{
          province_id: filter.province_id,
          district_id: filter.district_id,
          local_level_id: filter.local_level_id,
          drVisible: layerVisibility.drVisible,
          sdrVisible: layerVisibility.sdrVisible,
          mcVisible: layerVisibility.mcVisible,
          geneVisible: layerVisibility.geneVisible,
          hstlVisible: layerVisibility.hstlVisible,
          dotsVisible: layerVisibility.dotsVisible,
          cltrVisible: layerVisibility.cltrVisible,
        }}
      />
    </div>
  );
}

export default SDPPieChart;
