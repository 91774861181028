import { call, put, takeLatest } from "redux-saga/effects";
import { login } from "../services/login";
import {
  getTokenRequest,
  getTokenSuccess,
  getTokenFailure,
} from "../slices/login";

function* getTokenTask(action) {
  const { payload } = action;
  try {
    const response = yield call(login, payload);
    yield put(getTokenSuccess(response.data));
  } catch (error) {
    yield put(getTokenFailure());
  }
}

function* tokenWatcher() {
  yield takeLatest(getTokenRequest, getTokenTask);
}

export default tokenWatcher;
