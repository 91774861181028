import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { BASE_URL, NTPMIS_URL } from "../../constants";
import { deepEqual } from "../../utils/deepEqual";
import { arrayReducer } from "../../utils/arrayReducer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HFData = ({ params }) => {
  const admin = useSelector((state) => state.admin);

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      title: {
        display: true,
        text: "TB Outcome",
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const [chartData, setChartData] = useState({
    labels: [
      "TB_TREATMENT_SUCCESS_RATE",
      "TB_CURED_RATE",
      "TB_FAILURE_RATE",
      "TB_MORTALITY_RATE",
      "TB_LOST_TO_FOLLOWUP_RATE",
      "TB_NOT_EVALUATED_RATE",
    ],
    datasets: [],
  });
  useEffect(() => {
    fetch(BASE_URL + "tboutcome/" + new URLSearchParams(params)).then((res) =>
      res.json().then((result) => {
        let labels = [
          "TB_CURED_RATE",
          "TB_FAILURE_RATE",
          "TB_MORTALITY_RATE",
          "TB_LOST_TO_FOLLOWUP_RATE",
          "TB_NOT_EVALUATED_RATE",
          "TB_TREATMENT_SUCCESS_RATE",
        ];
        const datasets = [
          {
            backgroundColor: "rgb(255, 99, 132)",
            label: "TB Outcome",
            data: labels.map((e) =>
              result.data
                .filter((f) => f.item_key === e)
                .reduce((prev, current) => {
                  return parseFloat(prev) + parseFloat(current.value);
                }, 0)
            ),
          },
        ];
        const data = {
          labels,
          datasets,
        };
        setChartData(data);
      })
    );

    return () => {};
  }, [admin.districts, admin.local_levels, admin.provinces, params]);

  return (
    <div style={{ height: "50vh" }}>
      <Bar options={options} data={chartData} />
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  return deepEqual(prevProps, nextProps);
}

export default React.memo(HFData, areEqual);
