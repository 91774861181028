import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setRouteNumber } from "../../slices/route";
function refreshPage() {
  window.location.reload(false);
}
function MapRoutes() {
  const layerVisibility = useSelector((state) => state.layer);
  const route = useSelector((state) => state.route.graphhopperRoute);
  const dispatch = useDispatch();
  return (
    <div>
      <h5>Route Directions</h5>
      {/* <div
        type="button"
        className="btn btn-primary btn-block"
        onClick={(e) => {
          dispatch(setRouteNumber());
        }}
      >
        Get Route
      </div> */}
      {/* {route.paths[0].distance !== 0 && (
        <div
          type="button"
          className="btn btn-primary btn-block m-2"
          onClick={(e) => {
            refreshPage();
          }}
        >
          Clear Route
        </div>
      )} */}
      {route.paths[0].distance !== 0 ? (
        <ul className="list-group w-50 mt-5">
          <li className="list-group-item">
            Estimated Distance: {route.paths[0].distance} meters
          </li>
        </ul>
      ) : (
        <h6>No directions available</h6>
      )}
      {route.paths[0].time !== 0 ? (
        <ul className="list-group w-50">
          <li className="list-group-item">
            Estimated Time by car: {Math.floor(route.paths[0].time / 3600)}{" "}
            minutes
          </li>
        </ul>
      ) : (
        <>
          <h6>Please select health facility</h6>
          <h6> from map and get route</h6>
          <h6> for directions</h6>
        </>
      )}
      <ul className="list-group w-50 mt-2">
        {route.paths[0].instructions.map((e) => {
          return <li className="list-group-item">{e.text}</li>;
        })}
      </ul>
    </div>
  );
}

export default MapRoutes;
