import React from "react";
import Map from "../../MapOpenlayers/Map";

import { fromLonLat } from "ol/proj";
import { Layers } from "../../MapOpenlayers/Layers";
import LayerSwitcherControl from "../../MapOpenlayers/Controls/LayerSwitcherControl";
import { InputLayer } from "../../MapOpenlayers/Layers/InputLayer";

const mapCenter = fromLonLat([85, 28.5]);
const mapZoom = 7.2;
function Mapinput({ onChange, defaultMarker }) {
  return (
    <div className="row">
      <div className="col-md-12 position-relative">
        <Map
          id="map"
          className="map"
          cssStyle={{ height: "350px", width: "100%" }}
          center={mapCenter}
          zoom={mapZoom}
        >
          <Layers>
            <LayerSwitcherControl />
            <InputLayer defaultMarker={defaultMarker} onChange={onChange} />
          </Layers>
        </Map>
      </div>
    </div>
  );
}

export default Mapinput;
