import WebMap from "./pages/webmap";
import CaseMap from "./pages/casesmap";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Data from "./pages/data";
import AddData from "./pages/adddata";
import About from "./pages/about";
import Charts from "./pages/charts";
import Login from "./pages/Login";
import "./App.css";
import addTopBar from "./utils/addTopBar";
import addSidebar from "./utils/addSideBar";
import Embed from "./pages/embed";
import MapDownload from "./pages/mapdownload";
import FiscalYearTopbar from "./components/Topbar/FiscalYearTopbar";
import OutreachMap from "./pages/outreachmap";
import BufferTopbar from "./components/Topbar/BufferTopbar";
import SuccessMap from "./pages/successmap";
import NotificationMap from "./pages/notificationmap";
import OrganizationalStructure from "./pages/organizationalstructure";
import SDPChart from "./pages/sdpchart";
import APICharts from "./pages/apicharts";
import CasesChart from "./pages/caseschart";
import AdminSelectBar from "./components/Topbar/AdminSelectBar";
import RouteMap from "./pages/routemap";
import TypeToggleBar from "./components/Topbar/TypeToggleBar";
import NotificationNumberMap from "./pages/notificationnumbermap";
import RawData from "./pages/rawdata";
import WebMapForExport from "./pages/webmapforexport";
import SDPPieChart from "./pages/sdppiechart";
import SDPRadarChart from "./pages/sdpradarchart";
import LinkMap from "./pages/linkmap";

function App() {
  return (
    <div className="App">
      <div className="row vh-100 gx-0 gy-0">
        <Router>
          <Routes>
            <Route path="/" element={addSidebar(addTopBar(<WebMap />))} />
            <Route path="/webmap" element={addSidebar(addTopBar(<WebMap />))} />
            <Route path="/webmap-export" element={addSidebar(addTopBar(<WebMapForExport />))}/>
            <Route path="/linkage-map" element={addSidebar(addTopBar(<LinkMap />))}/>
            <Route path="/routemap"element={addSidebar(
                <>
                  <div className="row gx-0 gy-0 p-2">
                    <TypeToggleBar />
                  </div>
                  <div className="row flex-grow-1 gx-0 gy-0">
                    <RouteMap />
                  </div>
                </>
              )}
            />
            <Route
              path="/successmap"
              element={addSidebar(
                <>
                  <AdminSelectBar />
                  <div className="row gx-0 gy-0">
                    <FiscalYearTopbar />
                  </div>
                  <div className="row flex-grow-1 gx-0 gy-0">
                    <SuccessMap />{" "}
                  </div>
                </>
              )}
            />
            <Route
              path="/notificationmap"
              element={addSidebar(
                <>
                  <AdminSelectBar />
                  <div className="row gx-0 gy-0">
                    <FiscalYearTopbar />
                  </div>
                  <div className="row flex-grow-1 gx-0 gy-0">
                    <NotificationMap />{" "}
                  </div>
                </>
              )}
            />
            <Route
              path="/notificationnumbermap"
              element={addSidebar(
                <>
                  <AdminSelectBar />
                  <div className="row gx-0 gy-0">
                    <FiscalYearTopbar />
                  </div>
                  <div className="row flex-grow-1 gx-0 gy-0">
                    <NotificationNumberMap />
                  </div>
                </>
              )}
            />
            <Route
              path="/casemap"
              element={addSidebar(
                <>
                  <AdminSelectBar />
                  <div className="row gx-0 gy-0">
                    <FiscalYearTopbar />
                  </div>
                  <div className="row flex-grow-1 gx-0 gy-0">
                    <CaseMap />
                  </div>
                </>
              )}
            />
            <Route
              path="/buffermap"
              element={addSidebar(
                <>
                  <AdminSelectBar />
                  <div className="row flex-grow-1 gx-0 gy-0">
                    <OutreachMap />
                  </div>
                </>
              )}
            />
            <Route path="/data" element={addSidebar(addTopBar(<Data />))} />
            <Route
              path="/rawdata"
              element={addSidebar(
                <>
                  <AdminSelectBar />
                  <div className="row flex-grow-1 gx-0 gy-0">
                    <RawData />
                  </div>
                </>
              )}
            />
            <Route
              path="/sdpchart"
              element={addSidebar(addTopBar(<SDPChart />))}
            />
            <Route
              path="/sdpradarchart"
              element={addSidebar(addTopBar(<SDPRadarChart />))}
            />
            <Route
              path="/sdppiechart"
              element={addSidebar(addTopBar(<SDPPieChart />))}
            />
            <Route
              path="/casechart"
              element={addSidebar(
                <>
                  <div className="row gx-0 gy-0">
                    <BufferTopbar />
                  </div>
                  <div className="row flex-grow-1 gx-0 gy-0">
                    <CasesChart />
                  </div>
                </>
              )}
            />
            <Route path="/apicharts" element={addSidebar(<APICharts />)} />
            <Route path="/adddata" element={addSidebar(<AddData />)} />
            <Route path="/aboutus" element={addSidebar(<About />)} />
            <Route path="/charts" element={addSidebar(addTopBar(<Charts />))} />
            <Route path="/login" element={<Login />} />
            <Route path="/mapdownload" element={addSidebar(<MapDownload />)} />
            <Route
              path="/organizationaltree"
              element={addSidebar(<OrganizationalStructure />)}
            />
            <Route path="/embed" element={addSidebar(<Embed />)} />
            <Route
              path="/embed/sdp"
              element={
                <div className="col-md-12 h-100 d-flex flex-column">
                  {addTopBar(<WebMap />)}
                </div>
              }
            />
            <Route
              path="/embed/casemap"
              element={
                <div className="col-md-12 h-100 d-flex flex-column">
                  <AdminSelectBar />
                  <div className="row gx-0 gy-0">
                    <FiscalYearTopbar />
                  </div>
                  <div className="row flex-grow-1 gx-0 gy-0">
                    <CaseMap />
                  </div>
                </div>
              }
            />
            <Route
              path="/embed/successmap"
              element={
                <div className="col-md-12 h-100 d-flex flex-column">
                  <AdminSelectBar />
                  <div className="row gx-0 gy-0">
                    <FiscalYearTopbar />
                  </div>
                  <div className="row flex-grow-1 gx-0 gy-0">
                    <SuccessMap />{" "}
                  </div>
                </div>
              }
            />
            <Route
              path="/embed/notificationrate"
              element={
                <div className="col-md-12 h-100 d-flex flex-column">
                  <AdminSelectBar />
                  <div className="row gx-0 gy-0">
                    <FiscalYearTopbar />
                  </div>
                  <div className="row flex-grow-1 gx-0 gy-0">
                    <NotificationMap />
                  </div>
                </div>
              }
            />
            <Route
              path="/embed/notificationnumber"
              element={
                <div className="col-md-12 h-100 d-flex flex-column">
                  <AdminSelectBar />
                  <div className="row gx-0 gy-0">
                    <FiscalYearTopbar />
                  </div>
                  <div className="row flex-grow-1 gx-0 gy-0">
                    <NotificationNumberMap />
                  </div>
                </div>
              }
            />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
