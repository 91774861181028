import { useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import dots from "../../../assets/icons/DOTS.png";
import drcenter from "../../../assets/icons/DRcenter.png";
import geneX from "../../../assets/icons/GeneX.png";
import lpa from "../../../assets/icons/LPA.png";
import mc from "../../../assets/icons/mc.png";
import hstl from "../../../assets/icons/Hstl.png";
import home from "../../../assets/icons/Home.png";
import subdr from "../../../assets/icons/SubDR.png";
import LegendControl from "ol-ext/control/Legend";
import OLLegend from "ol-ext/legend/Legend";
import MapContext from "../MapContext";
import { Style, Icon } from "ol/style";

function Legend() {
  const { map } = useContext(MapContext);

  const layerVisibility = useSelector((state) => state.layer);
  useEffect(() => {
    if (!map) return;
    var legend = new OLLegend({
      title: "Legend",
      size: [16, 16],
      margin: 6,
    });
    var legendCtrl = new LegendControl({
      legend: legend,
    });
    if (layerVisibility.healthFacilitiesVisible && layerVisibility.drVisible)
      legend.addItem({
        title: "DR Center",
        typeGeom: "Point",
        style: new Style({
          image: new Icon({
            src: drcenter,
          }),
        }),
      });
    if (layerVisibility.healthFacilitiesVisible && layerVisibility.sdrVisible)
      legend.addItem({
        title: "SDR Center",
        typeGeom: "Point",
        style: new Style({
          image: new Icon({
            src: subdr,
          }),
        }),
      });
    if (layerVisibility.healthFacilitiesVisible && layerVisibility.geneVisible)
      legend.addItem({
        title: "GeneX Center",
        typeGeom: "Point",
        style: new Style({
          image: new Icon({
            src: geneX,
          }),
        }),
      });
    if (layerVisibility.healthFacilitiesVisible && layerVisibility.cltrVisible)
      legend.addItem({
        title: "Culture LPA",
        typeGeom: "Point",
        style: new Style({
          image: new Icon({
            src: lpa,
          }),
        }),
      });
    if (layerVisibility.healthFacilitiesVisible && layerVisibility.dotsVisible)
      legend.addItem({
        title: "DOTS",
        typeGeom: "Point",
        style: new Style({
          image: new Icon({
            src: dots,
          }),
        }),
      });
    if (layerVisibility.healthFacilitiesVisible && layerVisibility.mcVisible)
      legend.addItem({
        title: "Microscopic Center",
        typeGeom: "Point",
        style: new Style({
          image: new Icon({
            src: mc,
          }),
        }),
      });
    if (layerVisibility.healthFacilitiesVisible && layerVisibility.hstlVisible)
      legend.addItem({
        title: "Hostel",
        typeGeom: "Point",
        style: new Style({
          image: new Icon({
            src: hstl,
          }),
        }),
      });
    if (layerVisibility.healthFacilitiesVisible && layerVisibility.homeVisible)
      legend.addItem({
        title: "Home",
        typeGeom: "Point",
        style: new Style({
          image: new Icon({
            src: home,
          }),
        }),
      });
    map.addControl(legendCtrl);

    return () => {
      map.controls.remove(legendCtrl);
    };
  }, [
    layerVisibility.cltrVisible,
    layerVisibility.dotsVisible,
    layerVisibility.drVisible,
    layerVisibility.etbcasesVisible,
    layerVisibility.geneVisible,
    layerVisibility.healthFacilitiesVisible,
    layerVisibility.homeVisible,
    layerVisibility.hstlVisible,
    layerVisibility.mcVisible,
    layerVisibility.sdrVisible,
    map,
  ]);

  return null;
}

export default Legend;
