/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDistrict,
  setLocalLevel,
  setProvince,
  setProvinceList,
  setDistrictList,
  setLocalLevelList,
} from "../../../slices/adminvalues";
import { BASE_URL } from "../../../constants";

import "./index.css";
import Mapinput from "../MapInput";

function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function AddData({ toggleModal, state, setState }) {
  const dispatch = useDispatch();
  const { provinces, districts, local_levels } = useSelector(
    (state) => state.admin
  );

  function getDistricts(provinceId) {
    if (provinceId == null) {
      dispatch(setDistrictList([]));
      return;
    }
    fetch(BASE_URL + `district/?province_id=${provinceId}`).then((res) => {
      res.json().then((result) => dispatch(setDistrictList(result)));
    });
  }
  function getLocalLevel(districtId) {
    if (districtId == null) {
      dispatch(setLocalLevelList([]));
      return;
    }
    fetch(BASE_URL + `locallevel/?district_id=${districtId}`).then((res) => {
      res.json().then((result) => dispatch(setLocalLevelList(result)));
    });
  }
  const onSubmit = (e) => {
    e.preventDefault();
    const fields = state.formfields;

    let url = `${BASE_URL}hfdata/`;
    if (fields.id) {
      url = `${BASE_URL}hfdata/${fields.id}/`;
    }

    fetch(url, {
      method: fields.id ? "PUT" : "POST",
      body: JSON.stringify({
        ...fields,
        code: fields.code,
        district: fields.district_id,
        hf_type: fields.hf_type,
        local_level: fields.local_level_id,
        local_level_type: "Gaupalika",
        location: `SRID=4326;POINT (${fields.location[0]} ${fields.location[1]})`,
        province: fields.province_id,
        sdc_name: fields.sdc_name,
        ntp_id: fields.ntp_id,
      }),

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")} `,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const newState = { ...state };
        if (res.id) {
          newState.allData = [
            res,
            ...state.allData.filter((d) => d.id !== res.id),
          ];
          newState.paginatedData = {
            data: paginate(
              newState.allData,
              newState.paginatedData.data.length,
              1
            ),
            nextPage: 2,
          };

          newState.formfields = {
            sdc_name: "",
            hf_type: "",
            is_dr: false,
            is_sdr: false,
            is_gene: false,
            is_mc: false,
            is_dots: false,
            is_hostel_home: false,
            is_cltr_lpa: false,
            location: [87.30633, 26.38311],
            province_id: null,
            district_id: null,
            local_level_id: null,
            code: 0,
          };

          toggleModal();

          setState({
            ...newState,
          });
          alert(`Successfully ${fields.id ? "Updated" : "Added"}`);
        } else {
          alert(Object.keys(res).toString() + " are Required");
        }
      });
  };

  const handleChange = (key, value) => {
    setState({
      ...state,
      formfields: {
        ...state.formfields,
        [key]: value,
      },
    });
  };

  useEffect(() => {
    if (!state.formfields.id) return;
    getDistricts(state.formfields.province_id);
    getLocalLevel(state.formfields.province_id);
  }, []);

  return (
    <>
      <div id="demo-modal" class="modal">
        <div class="modal__content">
          <form
            onSubmit={onSubmit}
            className="is-scroll"
            style={{
              maxHeight: "80vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div className="form-group">
              <label for="sdc_name">SDC Name</label>
              <input
                type="text"
                className="form-control"
                id="sdc_name"
                aria-describedby="emailHelp"
                placeholder="SDC Name"
                onChange={(e) => {
                  handleChange("sdc_name", e.target.value);
                }}
                value={state.formfields.sdc_name}
              />
            </div>
            <div className="form-group">
              <label for="sdc_name">NTP ID</label>
              <input
                type="text"
                className="form-control"
                id="ntp_id"
                aria-describedby="emailHelp"
                placeholder="NTP ID"
                onChange={(e) => {
                  handleChange("ntp_id", e.target.value);
                }}
                value={state.formfields.ntp_id}
              />
            </div>
            <div className="form-group">
              <label>Code</label>
              <input
                type="number"
                className="form-control"
                id="codenum"
                placeholder="Code"
                onChange={(e) => {
                  handleChange("code", e.target.value);
                }}
                value={state.formfields.code}
              />
            </div>
            <div className="form-group">
              <label>Ward</label>
              <input
                type="number"
                className="form-control"
                id="ward"
                placeholder="Ward"
                onChange={(e) => {
                  handleChange("ward", e.target.value);
                }}
                value={state.formfields.ward}
              />
            </div>
            <div className="form-group">
              <label>Location</label>
              {/* <input
                type="text"
                className="form-control"
                id="location"
                aria-describedby="emailHelp"
                placeholder="SRID=4326;POINT (87.30633 26.38311)"
                onChange={(e) => {
                  handleChange("location", e.target.value);
                }}
              /> */}
              <Mapinput
                onChange={(lnglt) => {
                  handleChange("location", lnglt);
                }}
                defaultMarker={state.formfields.location}
              />
            </div>
            <div className="form-group">
              <select
                class="form-select"
                onChange={(e) => {
                  handleChange("hf_type", e.target.value);
                }}
              >
                <option value={-1}>HF Type</option>
                {[
                  "Health Post",
                  "PHC",
                  "Red Cross",
                  "Hospital",
                  "College",
                  "UHC",
                  "Others",
                ].map((e) => {
                  return (
                    <option key={e} value={e} data-name={e}>
                      {e}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="form-group">
              {/* <label for="province">Province</label> */}
              <select
                class="form-select"
                onChange={(e) => {
                  if (e.target.value < 0) {
                    dispatch(setProvince(null));
                    getDistricts(null);
                    getLocalLevel(null);
                    return;
                  }

                  setState({
                    ...state,
                    formfields: {
                      ...state.formfields,
                      province:
                        e.target.options[e.target.selectedIndex].dataset.name,
                      province_id: e.target.value,
                    },
                  });

                  getDistricts(e.target.value);
                }}
                value={state.formfields.province_id}
              >
                <option
                  value={-1}
                  selected={state.formfields.province_id === null}
                >
                  Province
                </option>
                {provinces.map((pr) => {
                  return (
                    <option key={pr.id} value={pr.id} data-name={pr.name_en}>
                      {pr.name_en}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              {/* <label for="districts">Districts</label> */}
              <select
                class="form-select"
                onChange={(e) => {
                  if (e.target.value < 0) {
                    dispatch(setProvince(null));
                    getDistricts(null);
                    getLocalLevel(null);
                    return;
                  }
                  // setState({
                  //   ...state,
                  //   formfields: {
                  //     ...state.formfields,
                  //     district_id: e.target.value,
                  //   },
                  // });

                  setState({
                    ...state,
                    formfields: {
                      ...state.formfields,
                      district:
                        e.target.options[e.target.selectedIndex].dataset.name,
                      district_id: e.target.value,
                    },
                  });

                  getLocalLevel(e.target.value);
                }}
                value={state.formfields.district_id}
              >
                <option
                  value={-1}
                  selected={state.formfields.district_id === null}
                >
                  District
                </option>
                {districts.map((pr) => {
                  return (
                    <option key={pr.id} value={pr.id} data-name={pr.name_en}>
                      {pr.name_en}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              {/* <label for="local_level">Local Level</label> */}
              <select
                class="form-select"
                onChange={(e) => {
                  if (e.target.value < 0) {
                    dispatch(setProvince(null));
                    getDistricts(null);
                    getLocalLevel(null);
                    return;
                  }
                  // setState({
                  //   ...state,
                  //   formfields: {
                  //     ...state.formfields,
                  //     local_level_id: e.target.value,
                  //   },
                  // });

                  setState({
                    ...state,
                    formfields: {
                      ...state.formfields,
                      local_level:
                        e.target.options[e.target.selectedIndex].dataset.name,
                      local_level_id: e.target.value,
                    },
                  });
                }}
                value={state.formfields.local_level_id}
              >
                <option
                  value={-1}
                  selected={state.formfields.local_level_id === null}
                >
                  Local Level
                </option>
                {local_levels.map((pr) => {
                  return (
                    <option key={pr.id} value={pr.id} data-name={pr.name_en}>
                      {pr.name_en}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="row px-3">
              <div className="form-check col-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="is_dr"
                  onChange={(e) => {
                    handleChange("is_dr", e.target.checked);
                  }}
                  checked={state.formfields.is_dr}
                />
                <label className="form-check-label" for="is_dr">
                  is_dr
                </label>
              </div>

              <div className="form-check col-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="is_sdr"
                  onChange={(e) => {
                    handleChange("is_sdr", e.target.checked);
                  }}
                  checked={state.formfields.is_sdr}
                />
                <label className="form-check-label" for="is_sdr">
                  is_sdr
                </label>
              </div>
              <div className="form-check col-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="is_gene"
                  onChange={(e) => {
                    handleChange("is_gene", e.target.checked);
                  }}
                  checked={state.formfields.is_gene}
                />
                <label className="form-check-label" for="is_gene">
                  is_gene
                </label>
              </div>
              <div className="form-check col-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="is_mc"
                  onChange={(e) => {
                    handleChange("is_mc", e.target.checked);
                  }}
                  checked={state.formfields.is_mc}
                />
                <label className="form-check-label" for="is_mc">
                  is_mc
                </label>
              </div>
              <div className="form-check col-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="is_dots"
                  onChange={(e) => {
                    handleChange("is_dots", e.target.checked);
                  }}
                  checked={state.formfields.is_dots}
                />
                <label className="form-check-label" for="is_dots">
                  is_dots
                </label>
              </div>
              <div className="form-check col-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="is_hostel_home"
                  checked={state.formfields.is_hostel_home}
                  onChange={(e) => {
                    handleChange("is_hostel_home", e.target.checked);
                  }}
                />
                <label className="form-check-label" for="is_hostel_home">
                  is_hostel_home
                </label>
              </div>
              <div className="form-check col-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="is_cltr_lpa"
                  checked={state.formfields.is_cltr_lpa}
                  onChange={(e) => {
                    handleChange("is_cltr_lpa", e.target.checked);
                  }}
                />
                <label className="form-check-label" for="is_cltr_lpa">
                  is_cltr_lpa
                </label>
              </div>
              <div className="form-check col-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="is_home"
                  checked={state.formfields.is_home}
                  onChange={(e) => {
                    handleChange("is_home", e.target.checked);
                  }}
                />
                <label className="form-check-label" for="is_home">
                  is_home
                </label>
              </div>
            </div>

            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </form>

          <div className="modal__footer"></div>

          <a
            onClick={() => {
              toggleModal();
            }}
            class="modal__close"
          >
            &times;
          </a>
        </div>
      </div>
    </>
  );
}

export default AddData;
