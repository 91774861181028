import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  legendSettings: {
    visible: false,
  },
  scaleSettings: {
    visible: true,
    type: "scalebar",
    units: "metric",
    steps: 4,
    showScaleText: true,
  },
};

const mapSettingSlice = createSlice({
  name: "mapsettings",
  initialState,
  reducers: {
    setLegendVisibility(state, { payload }) {
      state.legendSettings.visible = payload;
    },
    setScaleVisibility(state, { payload }) {
      state.scaleSettings.visible = payload;
    },
    setScaleSteps(state, { payload }) {
      state.scaleSettings.steps = payload;
    },
    setScaleUnits(state, { payload }) {
      state.scaleSettings.units = payload;
    },
    setScaleType(state, { payload }) {
      state.scaleSettings.type = payload;
    },
    setScaleShowScaleText(state, { payload }) {
      state.scaleSettings.showScaleText = payload;
    },
  },
});

export const {
  setLegendVisibility,
  setScaleVisibility,
  setScaleShowScaleText,
  setScaleSteps,
  setScaleType,
  setScaleUnits,
} = mapSettingSlice.actions;

export default mapSettingSlice.reducer;
