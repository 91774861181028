import React, { useEffect, useState } from "react";
import { BASE_URL } from "../constants";
import Select from "react-select";

export default function MapDownload() {
  const [files, SetFiles] = useState([]);
  const [folders, SetFolders] = useState([]);
  const [imageURL, SetImageURL] = useState(null);
  const [folderName, setFolderName] = useState("");

  useEffect(() => {
    fetch(BASE_URL + "getimagefiles/").then((res) => {
      res.json().then((result) => SetFolders(result.files));
    });
  }, []);

  function handleFolderSelect(value) {
    setFolderName(value.value);
    SetImageURL(null);
    SetFiles([]);
    fetch(`${BASE_URL}getimagefiles/?dire=${value.value}`).then((res) => {
      res.json().then((result) => SetFiles(result.files));
    });
  }
  function handleFileSelect(value) {
    SetImageURL(
      `${BASE_URL}getimagefiles/?dire=${folderName}&filename=${value.value.replace(
        "&",
        "%26"
      )}`
    );
  }

  return (
    <div className="p-5">
      <h5>Maps download</h5>
      <div className="row gy-0 gx-0">
        <div className="col-md-3">
          <Select
            placeholder="Select District"
            onChange={handleFolderSelect}
            options={folders.map((e) => {
              return { value: e, label: e };
            })}
          />
        </div>
        <div className="col-md-3">
          <Select
            placeholder="Map"
            onChange={handleFileSelect}
            options={files.map((e) => {
              return { value: e, label: e.split(".")[0] };
            })}
          />
        </div>
      </div>
      <div className="row">
        {imageURL && (
          <>
            <a href={imageURL} className="btn btn-primary m-2">
              Download
            </a>
            <img src={imageURL} alt="Sorry couldnot load map"></img>
          </>
        )}
      </div>
    </div>
  );
}
