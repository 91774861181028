import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    userName: "",
    password: "",
  },
  loading: false,
};

const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    getTokenRequest(state, { payload }) {
      state.loading = true;
    },
    getTokenSuccess(state, { payload }) {
      state.loading = false;
    },
    getTokenFailure(state) {
      state.loading = false;
    },
  },
});

export const { getTokenRequest, getTokenFailure, getTokenSuccess } =
  tokenSlice.actions;

export default tokenSlice.reducer;
