import React from "react";
import TypeToggleBar from "./TypeToggleBar";
import AdminSelectBar from "./AdminSelectBar";

function Topbar() {
  return (
    <>
      <AdminSelectBar />
      <TypeToggleBar />
    </>
  );
}

export default Topbar;
