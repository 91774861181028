import React, { useEffect } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../../constants";
import { setFiscalYearId, setFiscalYears } from "../../slices/adminvalues";

export default function FiscalYearTopbar() {
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  useEffect(() => {
    fetch(BASE_URL + "fiscalyears/").then((res) => {
      res.json().then((result) => {
        dispatch(setFiscalYears(result));
        dispatch(setFiscalYearId(result.slice(-1)[0].id));
      });
    });
    return () => {};
  }, [dispatch]);
  function handleFiscalYearChange(value) {
    const id = value.value;
    console.log(id);
    dispatch(setFiscalYearId(id));
  }
  return (
    <>
      <div className="row gy-0 gx-0">
        <div className="col-md-3">
          <Select
            value={admin.fiscal_years
              .map((e) => {
                return { value: e.id, label: e.name };
              })
              .filter((e) => e.value === admin.adminFilter.fiscal_year_id)}
            placeholder="Select Fiscal Year"
            onChange={handleFiscalYearChange}
            options={admin.fiscal_years.map((e) => {
              return { value: e.id, label: e.name };
            })}
          />
        </div>
      </div>
    </>
  );
}
