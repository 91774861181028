import React, { useRef, useState, useEffect } from "react";
import "./map.css";
import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import * as ol from "ol";
import PropTypes from "prop-types";
import Overlay from "ol/Overlay";
import MapContext from "./MapContext";

const Map = ({
  children,
  zoom,
  center,
  className,
  id,
  cssStyle,
  getMapObject,
}) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [view, setView] = useState(null);
  // on component mount
  useEffect(() => {
    const viewObj = new ol.View({
      center,
      padding: [50, 50, 30, 150],
      zoom,
      maxZoom: 18,
      minZoom: 7,
    });
    const options = {
      view: viewObj,
      layers: [],
      controls: [],
      projection: "EPSG:3857",
    };
    const mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);
    setView(viewObj);
    getMapObject(mapObject);

    global.map = mapObject;
    return () => mapObject.setTarget(undefined);
  }, [center, getMapObject, zoom]);
  // zoom change handler
  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
  }, [map, zoom]);
  return (
    <MapContext.Provider
      value={{
        map,
        view,
      }}
    >
      <div ref={mapRef} className={className} id={id} style={cssStyle}>
        {children}
      </div>
    </MapContext.Provider>
  );
};
Map.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  center: PropTypes.arrayOf(PropTypes.number, PropTypes.number),
  zoom: PropTypes.number,
  cssStyle: PropTypes.any,
  getMapObject: PropTypes.func,
};

Map.defaultProps = {
  className: "map",
  id: "mapid",
  children: null,
  center: [84, 28],
  zoom: 6,
  getMapObject: () => {},
};
export default Map;
