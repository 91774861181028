import React, { useEffect, useContext } from "react";
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "../../../store";
import MapContext from "../MapContext";
import { SidePanel as SP } from "ol-side-panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRoute } from "@fortawesome/free-solid-svg-icons";
import MapRoutes from "../../Routing/MapRoute";

function OLSidePanel() {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;
    const sidePanel = new SP();
    map.controls.push(sidePanel);

    const routesPane = sidePanel.definePane({
      paneId: "routes",
      name: "Routes",
      icon: ReactDOMServer.renderToString(
        <FontAwesomeIcon className="px-2" icon={faRoute} />
      ),
    });
    const routesGreeting = document.createElement("div");
    const routePane = ReactDOM.createRoot(routesGreeting);
    routePane.render(
      <Provider store={store}>
        <MapRoutes />
      </Provider>
    );
    routesPane.addWidgetElement(routesGreeting);

    return () => {
      map.controls.remove(sidePanel);
    };
  }, [map]);

  return null;
}

export default OLSidePanel;
