import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setCltr,
  setDots,
  setDr,
  setGene,
  setHstl,
  setMc,
  setSubDr,
  setHome,
} from "../../slices/layers";
import drcenter from "../../assets/icons/DRcenter.png";
import dots from "../../assets/icons/DOTS.png";
import geneX from "../../assets/icons/GeneX.png";
import lpa from "../../assets/icons/LPA.png";
import mc from "../../assets/icons/mc.png";
import hstl from "../../assets/icons/Hstl.png";
import home from "../../assets/icons/Home.png";
import subdr from "../../assets/icons/SubDR.png";

function TypeToggleBar() {
  const dispatch = useDispatch();
  const layerVisibility = useSelector((state) => state.layer);
  return (
    <div className="row gy-0 gx 0">
      <ul className="nav nav-pills flex-row mb-auto d-flex justify-content-around">
        <li>
          <input
            disabled={!layerVisibility.healthFacilitiesVisible}
            onClick={(e) => {
              if (layerVisibility.drVisible) {
                dispatch(setDr(false));
              } else {
                dispatch(setDr(true));
              }
            }}
            className="form-check-input me-1"
            type="checkbox"
            checked={layerVisibility.drVisible}
            value={layerVisibility.drVisible}
            aria-label="..."
          />
          <img src={drcenter} alt="" className="" />
          DR
        </li>
        <li>
          <input
            disabled={!layerVisibility.healthFacilitiesVisible}
            onClick={(e) => {
              if (layerVisibility.sdrVisible) {
                dispatch(setSubDr(false));
              } else {
                dispatch(setSubDr(true));
              }
            }}
            className="form-check-input me-1"
            type="checkbox"
            checked={layerVisibility.sdrVisible}
            value={layerVisibility.sdrVisible}
            aria-label="..."
          />
          <img src={subdr} alt="" />
          SDR
        </li>
        <li>
          <input
            disabled={!layerVisibility.healthFacilitiesVisible}
            onClick={(e) => {
              if (layerVisibility.dotsVisible) {
                dispatch(setDots(false));
              } else {
                dispatch(setDots(true));
              }
            }}
            className="form-check-input me-1"
            type="checkbox"
            checked={layerVisibility.dotsVisible}
            value={layerVisibility.dotsVisible}
            aria-label="..."
          />
          <img src={dots} alt="" className="" />
          DOTS
        </li>

        <li>
          <input
            disabled={!layerVisibility.healthFacilitiesVisible}
            onClick={(e) => {
              if (layerVisibility.hstlVisible) {
                dispatch(setHstl(false));
              } else {
                dispatch(setHstl(true));
              }
            }}
            className="form-check-input me-1"
            type="checkbox"
            checked={layerVisibility.hstlVisible}
            value={layerVisibility.hstlVisible}
            aria-label="..."
          />
          <img src={hstl} alt="" />
          Hostel
        </li>
        <li>
          <input
            disabled={!layerVisibility.healthFacilitiesVisible}
            onClick={(e) => {
              if (layerVisibility.homeVisible) {
                dispatch(setHome(false));
              } else {
                dispatch(setHome(true));
              }
            }}
            className="form-check-input me-1"
            type="checkbox"
            checked={layerVisibility.homeVisible}
            value={layerVisibility.homeVisible}
            aria-label="..."
          />
          <img src={home} alt="" />
          Home
        </li>
        <li>
          <input
            disabled={!layerVisibility.healthFacilitiesVisible}
            onClick={(e) => {
              if (layerVisibility.cltrVisible) {
                dispatch(setCltr(false));
              } else {
                dispatch(setCltr(true));
              }
            }}
            className="form-check-input me-1"
            type="checkbox"
            checked={layerVisibility.cltrVisible}
            value={layerVisibility.cltrVisible}
            aria-label="..."
          />
          <img src={lpa} alt="" />
          Culture LPA
        </li>
        <li>
          <input
            disabled={!layerVisibility.healthFacilitiesVisible}
            onClick={(e) => {
              if (layerVisibility.geneVisible) {
                dispatch(setGene(false));
              } else {
                dispatch(setGene(true));
              }
            }}
            className="form-check-input me-1"
            type="checkbox"
            checked={layerVisibility.geneVisible}
            value={layerVisibility.geneVisible}
            aria-label="..."
          />
          <img src={geneX} alt="" />
          GeneX Center
        </li>
        <li>
          <input
            disabled={!layerVisibility.healthFacilitiesVisible}
            onClick={(e) => {
              if (layerVisibility.mcVisible) {
                dispatch(setMc(false));
              } else {
                dispatch(setMc(true));
              }
            }}
            className="form-check-input me-1"
            type="checkbox"
            checked={layerVisibility.mcVisible}
            value={layerVisibility.mcVisible}
            aria-label="..."
          />
          <img src={mc} alt="" />
          Microscopic Center
        </li>
      </ul>
    </div>
  );
}

export default TypeToggleBar;
