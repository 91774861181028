import { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import LegendControl from "ol-ext/control/Legend";
import OLLegend from "ol-ext/legend/Legend";
import MapContext from "../MapContext";
import {
  provinceStyleFunction,
  districtStyleFunction,
  localLevelStyleFunction,
} from "../Layers/TBNotificationNumberLayer";
import Feature from "ol/Feature";

function Legend() {
  const { map } = useContext(MapContext);

  const filter = useSelector((state) => state.admin.adminFilter);
  useEffect(() => {
    if (!map) return;
    var legend = new OLLegend({
      title: "Legend",
      margin: 4,
    });
    var legendCtrl = new LegendControl({
      legend: legend,
    });

    if (filter.district_ids.length) {
      legend.addItem({
        title: `100`,
        style: localLevelStyleFunction(
          new Feature({ notification_number: 100 })
        ),
        typeGeom: "Point",
      });
      legend.addItem({
        title: `50`,
        style: localLevelStyleFunction(
          new Feature({ notification_number: 50 })
        ),
        typeGeom: "Point",
      });
      legend.addItem({
        title: `25`,
        style: localLevelStyleFunction(
          new Feature({ notification_number: 25 })
        ),
        typeGeom: "Point",
      });
    } else if (filter.province_ids.length) {
      legend.addItem({
        title: `400`,
        style: districtStyleFunction(new Feature({ notification_number: 400 })),
        typeGeom: "Point",
      });
      legend.addItem({
        title: `200`,
        style: districtStyleFunction(new Feature({ notification_number: 200 })),
        typeGeom: "Point",
      });
      legend.addItem({
        title: `100`,
        style: districtStyleFunction(new Feature({ notification_number: 100 })),
        typeGeom: "Point",
      });
    } else {
      legend.addItem({
        title: `2000`,
        style: provinceStyleFunction(
          new Feature({ notification_number: 2000 })
        ),
        typeGeom: "Point",
      });
      legend.addItem({
        title: `1000`,
        style: provinceStyleFunction(
          new Feature({ notification_number: 1000 })
        ),
        typeGeom: "Point",
      });
      legend.addItem({
        title: `500`,
        style: provinceStyleFunction(new Feature({ notification_number: 500 })),
        typeGeom: "Point",
      });
    }
    map.addControl(legendCtrl);

    return () => {
      map.controls.remove(legendCtrl);
    };
  }, [filter.province_ids, filter.district_ids, map]);

  return null;
}

export default Legend;
