/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import AddData from "../components/Data/AddData";
import { BASE_URL } from "../constants";
import useIntersectionObserver from "../Hooks/IntersectionObserver";
import Loader from "../assets/smallloader.gif";
import { useSelector } from "react-redux";
import DeleteModal from "../components/Data/DeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}
function substringBetween(s, a, b) {
  var p = s.indexOf(a) + a.length;
  return s.substring(p, s.indexOf(b, p));
}

function Data() {
  const { province_ids, district_ids, local_level_ids } = useSelector(
    (state) => state.admin.adminFilter
  );
  const {
    drVisible,
    sdrVisible,
    dotsVisible,
    hstlVisible,
    cltrVisible,
    geneVisible,
    mcVisible,
  } = useSelector((state) => state.layer);

  const [isOpen, setIsOpen] = useState(false);
  const [token] = useState(localStorage.getItem("token"));

  const [state, setState] = useState({
    dataLoading: true,
    backupDataforFilter: [],
    allData: [],
    paginatedData: {
      data: [],
      nextPage: null,
    },
    formfields: {
      id: null,
      sdc_name: "",
      hf_type: "",
      is_dr: false,
      is_sdr: false,
      is_gene: false,
      is_mc: false,
      is_dots: false,
      is_hostel_home: false,
      is_cltr_lpa: false,
      is_home: false,
      location: [84.30633, 28.38311],
      province_id: null,
      district_id: null,
      local_level_id: null,
      code: 0,
      ntp_id: "",
      ward: 0,
    },
    deleteModal: {
      opened: false,
      id: null,
    },
  });

  const filterfunc = (da) => {
    return (
      (province_ids.length > 0
        ? province_ids.includes(da.province_id)
        : true) &&
      (district_ids.length > 0
        ? district_ids.includes(da.district_id)
        : true) &&
      (local_level_ids.length > 0
        ? local_level_ids.includes(da.local_level_id)
        : true) &&
      ((drVisible ? da.is_dr === true : false) ||
        (sdrVisible ? da.is_sdr === true : false) ||
        (dotsVisible ? da.is_dots === true : false) ||
        (hstlVisible ? da.is_hostel_home === true : false) ||
        (cltrVisible ? da.is_cltr_lpa === true : false) ||
        (geneVisible ? da.is_gene === true : false) ||
        (mcVisible ? da.is_mc === true : false))
    );
  };

  useEffect(() => {
    const filtered = state.backupDataforFilter.filter(filterfunc);

    setState({
      ...state,
      allData: [...filtered],
      paginatedData: {
        data: paginate([...filtered], 20, 1),
        nextPage: filtered.length < 20 ? null : 2,
      },
    });
  }, [
    province_ids,
    district_ids,
    local_level_ids,
    drVisible,
    sdrVisible,
    dotsVisible,
    hstlVisible,
    cltrVisible,
    geneVisible,
    mcVisible,
  ]);
  function toggleModal() {
    setIsOpen(!isOpen);
  }

  function fetchHFData(){
    setState({...state,dataLoading: true})
    fetch(BASE_URL + "fetch-hfdata-from-source", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")} `,
      },
    }).then((res) => {
      console.log(res)
      setState({...state,dataLoading: false})
      if(res.status === 200){
        fetchAndUpdateData()
      }else{
        alert('Something Wrong !!')
      }
    });
  }

  const [lastElementInView, LoadMoreRef, ParentLoadMoreRef] =
    useIntersectionObserver();

  const handleLoadMore = () => {
    if (!state.paginatedData.nextPage) return "";
    setState({
      ...state,
      paginatedData: {
        data: [
          ...state.paginatedData.data,
          ...paginate(state.allData, 20, state.paginatedData.nextPage),
        ],
        nextPage: state.paginatedData.nextPage + 1,
      },
    });
  };

  const onDelete = (row) => {
    fetch(`${BASE_URL}hfdata/${state.deleteModal.id + "/" || "/"}`, {
      method: "DELETE",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")} `,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const newState = { ...state };

        newState.allData = state.allData.filter(
          (d) => d.id !== state.deleteModal.id
        );
        newState.paginatedData = {
          data: paginate(
            newState.allData,
            newState.paginatedData.data.length,
            1
          ),
          nextPage: 2,
        };
        console.log("Delete");
        setState({
          ...newState,
          deleteModal: {
            opened: false,
            id: null,
          },
        });
        alert("Successfully Deleted");
      });
  };

  const onEdit = (row) => {
    setState({
      ...state,
      formfields: {
        ...row,
        location: substringBetween(row.location, "(", ")")
          .split(" ")
          .map(Number),
      },
    });
    toggleModal();
  };

  useEffect(() => {
    if (lastElementInView) {
      handleLoadMore();
    }
  }, [lastElementInView]);

// Function to fetch and update data
const fetchAndUpdateData = () => {
  fetch(BASE_URL + "hfdata/", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")} `,
    },
  }).then((res) => {
    res.json().then((result) => {
      setState({
        ...state,
        dataLoading:false,
        backupDataforFilter: result,
        allData: result.filter(filterfunc),
        paginatedData: {
          data: paginate(result, 20, 1),
          nextPage: 2,
        },
      });
    });
  });
};

useEffect(() => {
  fetchAndUpdateData()
}, []);

  return (
    <div className="container">
      {state.dataLoading && (
        <img alt="loader" src={Loader} style={{ width: 50, height: 50 }} />
      )}
      {token && (
        <div className="" class="p-2">
          <button
            onClick={() => {
              toggleModal();
            }}
            type="button"
            className="btn btn-primary"
          >
            Add Data
          </button>
          <button
            onClick={() => {
              fetchHFData();
            }}
            type="button"
            className="btn btn-warning pl-4 ml-4" style={{ marginLeft: '20px' }}
          >
           Fetch HF Data
          </button>
        </div>
      )}
      <div className="table-wrapper is-scroll" ref={ParentLoadMoreRef}>
        <table className="table">
          <thead>
            <tr>
              <td></td>
              <td></td>
              {Object.keys(state.paginatedData.data[0] || {}).map((header) => {
                return <th scope="col">{header}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {state.paginatedData.data.map((row) => {
              return (
                <tr>
                  {token && (
                    <>
                      <td
                        onClick={() => onEdit(row)}
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon className="px-2" icon={faEdit} />
                      </td>
                      <td
                        onClick={() => {
                          setState({
                            ...state,
                            deleteModal: {
                              opened: true,
                              id: row.id,
                            },
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon className="px-2" icon={faTrash} />
                      </td>
                    </>
                  )}
                  {Object.keys(state.paginatedData.data[0]).map((header) => {
                    return <td>{row[header]?.toString()}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot ref={LoadMoreRef}></tfoot>
        </table>
      </div>
      {isOpen && (
        <AddData toggleModal={toggleModal} state={state} setState={setState} />
      )}

      {state.deleteModal.opened && (
        <DeleteModal onDelete={onDelete} state={state} setState={setState} />
      )}
    </div>
  );
}

export default Data;
