import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const orgstructSlice = createSlice({
  name: "mapsettings",
  initialState,
  reducers: {
    setProvinceNode(state, { payload }) {
      state.data = [...payload];
    },
    setDistrictNode(state, { payload, id }) {
      console.log(payload, id);
    },
  },
});

export const { setProvinceNode, setDistrictNode } = orgstructSlice.actions;

export default orgstructSlice.reducer;
