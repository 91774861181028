import { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "ol/ol.css";
import MapContext from "../MapContext";
import MVT from "ol/format/MVT";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import { Style, Fill, Stroke } from "ol/style";
import chroma from "chroma-js";
import { BASE_URL } from "../../../constants";
import { transformExtent } from "ol/proj";
import { bbox } from "@turf/turf";
import {
  setDistrictIds,
  setLocalLevelIds,
  setProvinceIds,
} from "../../../slices/adminvalues";

export const buffercolorramp = chroma
  .scale(["#228B22", "#f2a72e", "#800020"])
  .mode("lch")
  .colors(3);
const vtSource2km = new VectorTileSource({
  maxZoom: 102,
  format: new MVT({
    idProperty: "iso_a3",
  }),
});
const vtLayer2km = new VectorTileLayer({
  zIndex: 555,
  source: vtSource2km,
  style: new Style({
    stroke: new Stroke({
      color: buffercolorramp[0],

      width: 0,
    }),
    fill: new Fill({
      color: buffercolorramp[0],
    }),
  }),
});
const vtSource5km = new VectorTileSource({
  maxZoom: 102,
  format: new MVT({
    idProperty: "iso_a3",
  }),
});
const vtLayer5km = new VectorTileLayer({
  zIndex: 444,
  source: vtSource5km,
  style: new Style({
    stroke: new Stroke({
      color: buffercolorramp[0],

      width: 0,
    }),
    fill: new Fill({
      color: buffercolorramp[1],
    }),
  }),
});
const vtSource10km = new VectorTileSource({
  maxZoom: 102,
  format: new MVT({
    idProperty: "iso_a3",
  }),
});
const vtLayer10km = new VectorTileLayer({
  zIndex: 333,
  source: vtSource10km,
  style: new Style({
    stroke: new Stroke({
      color: buffercolorramp[0],
      width: 0,
    }),
    fill: new Fill({
      color: buffercolorramp[2],
    }),
  }),
});
const provinceSource = new VectorTileSource({
  projection: "EPSG:3857",
  maxZoom: 288,
  format: new MVT({}),
});
const provinceLayer = new VectorTileLayer({
  renderMode: "vector",
  className: "province-layer",
  source: provinceSource,
  zIndex: 1111,
  style: new Style({
    stroke: new Stroke({
      color: "#000000",
      width: 1.5,
    }),
    fill: new Fill({
      color: "#00000000",
    }),
  }),
});
const districtSource = new VectorTileSource({
  projection: "EPSG:3857",
  maxZoom: 288,
  format: new MVT({}),
});
const districtLayer = new VectorTileLayer({
  className: "district-layer",
  source: districtSource,
  zIndex: 2222,
  style: new Style({
    stroke: new Stroke({
      color: "#000000",
      width: 1,
    }),
    fill: new Fill({
      color: "#00000000",
    }),
  }),
});
const localLevelSource = new VectorTileSource({
  projection: "EPSG:3857",
  maxZoom: 288,
  format: new MVT({}),
});
const localLevelLayer = new VectorTileLayer({
  className: "local-level-layer",
  source: localLevelSource,
  zIndex: 3333,
  style: new Style({
    stroke: new Stroke({
      color: "#000000",
      width: 0.5,
    }),
    fill: new Fill({
      color: "#00000000",
    }),
  }),
});
export default function BufferVectorTile() {
  const dispatch = useDispatch();
  const { map } = useContext(MapContext);
  const filter = useSelector((state) => state.admin.adminFilter);
  const layerVisibility = useSelector((state) => state.layer);

  useEffect(() => {
    if (!map) return;

    map.addLayer(vtLayer2km);
    map.addLayer(vtLayer5km);
    map.addLayer(vtLayer10km);
    map.addLayer(provinceLayer);
    map.addLayer(districtLayer);
    map.addLayer(localLevelLayer);
    return () => {
      if (map) {
        map.removeLayer(vtLayer2km);
        map.removeLayer(vtLayer5km);
        map.removeLayer(vtLayer10km);
        map.removeLayer(provinceLayer);
        map.removeLayer(districtLayer);
        map.removeLayer(localLevelLayer);
      }
    };
  }, [map]);

  useEffect(() => {
    if (!map) return;

    map.on("singleclick", (e) => {
      const { pixel } = e;
      localLevelLayer.getFeatures(pixel).then((clickedFeatures) => {
        if (clickedFeatures.length && layerVisibility.localLevelVisible) {
          const local_level_id = clickedFeatures[0].getProperties()["id"];
          dispatch(setLocalLevelIds([local_level_id]));
        } else {
          districtLayer.getFeatures(pixel).then((clickedFeatures) => {
            if (clickedFeatures.length && layerVisibility.districtsVisible) {
              const district_id = clickedFeatures[0].getProperties()["id"];
              dispatch(setDistrictIds([district_id]));
            } else {
              provinceLayer.getFeatures(pixel).then((clickedFeatures) => {
                if (
                  clickedFeatures.length &&
                  layerVisibility.provincesVisible
                ) {
                  const province_id = clickedFeatures[0].getProperties()["id"];
                  dispatch(setProvinceIds([province_id]));
                }
              });
            }
          });
        }
      });
    });

    return () => {};
  }, [
    dispatch,
    layerVisibility.districtsVisible,
    layerVisibility.localLevelVisible,
    layerVisibility.provincesVisible,
    map,
  ]);

  useEffect(() => {
    vtSource2km.setUrl(
      `${BASE_URL}outreach-tiles/{z}/{x}/{y}?buffer_dist=2000${
        filter.province_ids.length ? `&province_ids=${filter.province_ids}` : ""
      }${
        filter.district_ids.length ? `&district_ids=${filter.district_ids}` : ""
      }${
        filter.local_level_ids.length
          ? `&local_level_ids=${filter.local_level_ids}`
          : ""
      }`
    );
    vtSource5km.setUrl(
      `${BASE_URL}outreach-tiles/{z}/{x}/{y}?buffer_dist=5000${
        filter.province_ids.length ? `&province_ids=${filter.province_ids}` : ""
      }${
        filter.district_ids.length ? `&district_ids=${filter.district_ids}` : ""
      }${
        filter.local_level_ids.length
          ? `&local_level_ids=${filter.local_level_ids}`
          : ""
      }`
    );
    vtSource10km.setUrl(
      `${BASE_URL}outreach-tiles/{z}/{x}/{y}?buffer_dist=10000${
        filter.province_ids.length ? `&province_ids=${filter.province_ids}` : ""
      }${
        filter.district_ids.length ? `&district_ids=${filter.district_ids}` : ""
      }${
        filter.local_level_ids.length
          ? `&local_level_ids=${filter.local_level_ids}`
          : ""
      }`
    );

    return () => {};
  }, [filter.district_ids, filter.local_level_ids, filter.province_ids]);

  useEffect(() => {
    if (!map) return;
    if (filter.local_level_ids.length) {
      fetch(
        `${BASE_URL}locallevelextent/?local_level_ids=${filter.local_level_ids}`
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    } else if (filter.district_ids.length) {
      fetch(`${BASE_URL}districtextent/?district_ids=${filter.district_ids}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    } else if (filter.province_ids.length) {
      fetch(`${BASE_URL}provinceextent/?province_ids=${filter.province_ids}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    } else {
      fetch(`${BASE_URL}provinceextent/`)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    }
  }, [filter.local_level_ids, filter.district_ids, filter.province_ids, map]);
  useEffect(() => {
    provinceLayer.setVisible(layerVisibility.provincesVisible);
  }, [layerVisibility.provincesVisible]);
  useEffect(() => {
    districtLayer.setVisible(layerVisibility.districtsVisible);
  }, [layerVisibility.districtsVisible]);
  useEffect(() => {
    localLevelLayer.setVisible(layerVisibility.localLevelVisible);
  }, [layerVisibility.localLevelVisible]);

  useEffect(() => {
    provinceSource.clear();
    provinceSource.setUrl(`${BASE_URL}provincevt/{z}/{x}/{y}?fiscal_year=${1}`);
  }, []);

  useEffect(() => {
    districtSource.clear();
    districtSource.setUrl("");
    if (filter.province_ids.length) {
      districtSource.setUrl(
        `${BASE_URL}districtvt/{z}/{x}/{y}?fiscal_year=${1}&province_ids=${
          filter.province_ids
        }`
      );
    }
  }, [filter.province_ids]);

  useEffect(() => {
    if (!map) return;
    localLevelSource.clear();
    localLevelSource.setUrl("");

    if (filter.local_level_ids.length) {
      localLevelSource.setUrl(
        `${BASE_URL}locallevelvt/{z}/{x}/{y}?fiscal_year=${1}&locallevel_ids=${
          filter.local_level_ids
        }`
      );
    } else if (filter.district_ids.length) {
      localLevelSource.setUrl(
        `${BASE_URL}locallevelvt/{z}/{x}/{y}?fiscal_year=${1}&district_ids=${
          filter.district_ids
        }`
      );
    }
    return () => {};
  }, [map, filter.local_level_ids, filter.district_ids]);

  return null;
}
