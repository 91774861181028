import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { BASE_URL, NTPMIS_URL } from "../../constants";
import { deepEqual } from "../../utils/deepEqual";
import { arrayReducer } from "../../utils/arrayReducer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const HFData = ({ params }) => {
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "TB Reporting",
      },
    },
  };
  const [chartData, setChartData] = useState(0);
  useEffect(() => {
    fetch(BASE_URL + "tbreportingrate/").then((res) =>
      res.json().then((result) => {
        const sum = result.data.reduce((prev, current) => {
          return parseFloat(prev) + parseFloat(current.value);
        }, 0);

        setChartData(sum);
      })
    );

    return () => {};
  }, [params]);

  return (
    <div className="card m-2">
      <div className="card-header">TB Notified</div>
      <div className="card-body text-center font-weight-bold">{chartData}</div>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  return deepEqual(prevProps, nextProps);
}

export default React.memo(HFData, areEqual);
