import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Map from "../components/MapOpenlayers/Map";
import { Layers } from "../components/MapOpenlayers/Layers";
import LayerSwitcherControl from "../components/MapOpenlayers/Controls/LayerSwitcherControl";
import { fromLonLat } from "ol/proj";
import {
  Controls,
  FullScreenControl,
} from "../components/MapOpenlayers/Controls";
import ZoomControl from "../components/MapOpenlayers/Controls/ZoomControl";
import OLSidePanel from "../components/MapOpenlayers/SidePanel/RouteSidePanel";
import Legend from "../components/MapOpenlayers/Legend/Legend";
import Scale from "../components/MapOpenlayers/Scale/Scale";
import PrintMapControl from "../components/MapOpenlayers/PrintMap";
import LinkLayer from "../components/MapOpenlayers/Layers/LinkLayer";
import { BASE_URL } from "../constants";

const mapCenter = fromLonLat([85, 28.5]);

export default function LinkMap() {
  const [linkageData, setLinkageData] = useState([]);

  const getCoordinatesFromWKT = (localtion) => {
    const wkt = localtion;
    const regex = /POINT \((.*?)\)/;
    const result = wkt.match(regex)[1];
    const coordinates = result.split(" ");
    return [parseFloat(coordinates[0]), parseFloat(coordinates[1])];
  };
  const dispatch = useDispatch();
  useEffect(() => {
    fetch(`${BASE_URL}hflinkage`).then((response) =>
      response.json().then((result) => {
        const geojsonFeatures = result
          .filter((l) => l.hf_data?.location)
          .map((linkage) => {
            return {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: getCoordinatesFromWKT(linkage.hf_data.location),
              },
              properties: {
                ...(linkage?.hf_data ?? {}),
                links: linkage.hf_link_details.filter(l=>l.location).map((link) => {
                  return {
                    type: "Feature",
                    geometry: {
                      type: "Point",
                      coordinates: getCoordinatesFromWKT(link.location),
                    },
                    properties: link,
                  };
                }),
              },
            };
          });
        setLinkageData(geojsonFeatures);
      }),
    );
  }, [dispatch]);

  const mapSettings = useSelector((state) => state.mapSettings);
  return (
    <div className="row gx-0 gy-0">
      <div className="col-md-12 position-relative">
        <Map
          id="map"
          className="map"
          cssStyle={{ height: "100%", width: "100%" }}
          center={mapCenter}
        >
          <Legend />
          <Layers>
            <LayerSwitcherControl />
            <LinkLayer linkageData={linkageData} />
          </Layers>
          <Controls>
            {mapSettings.scaleSettings.visible && <Scale />}
            <PrintMapControl title="Linkage of TB Facilities" />
            <OLSidePanel />
            <FullScreenControl />
            <ZoomControl />
          </Controls>
        </Map>
      </div>
    </div>
  );
}
