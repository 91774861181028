import React from "react";
import { Link, useLocation } from "react-router-dom";

function NavLink({ children, toLink }) {
  const location = useLocation();
  return (
    <Link
      to={toLink}
      className={`nav-link ${
        location.pathname === toLink ? "active" : ""
      } text-white`}
      aria-current="page"
    >
      {children}
    </Link>
  );
}

export default NavLink;
