import React from "react";
import { useSelector } from "react-redux";
import Map from "../components/MapOpenlayers/Map";
import { Layers } from "../components/MapOpenlayers/Layers";
import LayerSwitcherControl from "../components/MapOpenlayers/Controls/LayerSwitcherControl";
import { fromLonLat } from "ol/proj";
import {
  Controls,
  FullScreenControl,
} from "../components/MapOpenlayers/Controls";
import ZoomControl from "../components/MapOpenlayers/Controls/ZoomControl";
import OLSidePanel from "../components/MapOpenlayers/SidePanel/SidePanelWebmap";
import Legend from "../components/MapOpenlayers/Legend/NotificationNumberLegend";
import Scale from "../components/MapOpenlayers/Scale/Scale";
import PrintMapControl from "../components/MapOpenlayers/PrintMap";
import TBNotificationNumberLayer from "../components/MapOpenlayers/Layers/TBNotificationNumberLayer";
const mapCenter = fromLonLat([85, 28.5]);
const mapZoom = 7.2;
export default function NotificationNumberMap() {
  const mapSettings = useSelector((state) => state.mapSettings);
  return (
    <div className="row gx-0 gy-0">
      <div className="col-md-12 position-relative">
        <Map
          id="map"
          className="map"
          cssStyle={{ height: "100%", width: "100%" }}
          center={mapCenter}
          zoom={mapZoom}
        >
          <Legend />
          <Layers>
            <LayerSwitcherControl />
            <TBNotificationNumberLayer />
          </Layers>
          <Controls>
            {mapSettings.scaleSettings.visible && <Scale />}
            <PrintMapControl title="TB Case Notification" />
            <OLSidePanel />
            <FullScreenControl />
            <ZoomControl />
          </Controls>
        </Map>
      </div>
    </div>
  );
}
