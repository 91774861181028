import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  healthFacilitiesVisible: true,
  districtsVisible: true,
  provincesVisible: true,
  localLevelVisible: true,
  countryVisible: false,
  roadVisible: false,
  drVisible: true,
  sdrVisible: true,
  dotsVisible: true,
  hstlVisible: true,
  homeVisible: true,
  cltrVisible: true,
  geneVisible: true,
  mcVisible: true,
  routeVisible: false,
  outreachBufferVisible: false,
  etbcasesVisible: false,
  opacity: {
    district: 1,
    province: 1,
    localLevel: 1,
  }
};

const layerSlice = createSlice({
  name: "layers",
  initialState,
  reducers: {
    setDistrictOpacity(state, { payload }) {
      state.opacity.district = payload;
    },
    setProvinceOpacity(state, { payload }) {
      state.opacity.province = payload;
    },
    setLocalLevelOpacity(state, { payload }) {
      state.opacity.localLevel = payload;
    },
    setEtbCases(state, { payload }) {
      state.etbcasesVisible = payload;
    },
    setHealth(state, { payload }) {
      state.healthFacilitiesVisible = payload;
    },
    setOutreach(state, { payload }) {
      state.outreachBufferVisible = payload;
    },
    setDr(state, { payload }) {
      state.drVisible = payload;
    },
    setSubDr(state, { payload }) {
      state.sdrVisible = payload;
    },
    setDots(state, { payload }) {
      state.dotsVisible = payload;
    },
    setHstl(state, { payload }) {
      state.hstlVisible = payload;
    },
    setHome(state, { payload }) {
      state.homeVisible = payload;
    },
    setMc(state, { payload }) {
      state.mcVisible = payload;
    },
    setGene(state, { payload }) {
      state.geneVisible = payload;
    },
    setCltr(state, { payload }) {
      state.cltrVisible = payload;
    },
    setDistrict(state, { payload }) {
      state.districtsVisible = payload;
    },
    setProvince(state, { payload }) {
      state.provincesVisible = payload;
    },
    setCountry(state, { payload }) {
      state.countryVisible = payload;
    },
    setLocalLevel(state, { payload }) {
      state.localLevelVisible = payload;
    },
    setRoad(state, { payload }) {
      state.roadVisible = payload;
    },
    setRoute(state, { payload }) {
      state.routeVisible = payload;
    },
  },
});

export const {
  setDistrict,
  setHealth,
  setProvince,
  setLocalLevel,
  setRoad,
  setCltr,
  setDots,
  setDr,
  setGene,
  setHstl,
  setMc,
  setSubDr,
  setRoute,
  setOutreach,
  setEtbCases,
  setCountry,
  setHome,
  setDistrictOpacity,
  setProvinceOpacity,
  setLocalLevelOpacity,
} = layerSlice.actions;

export default layerSlice.reducer;
