import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDistrict,
  setProvince,
  setLocalLevel,
  setCountry,
  setDistrictOpacity,
  setProvinceOpacity,
  setLocalLevelOpacity,
} from "../../slices/layers";

function LayerList() {
  const layerVisibility = useSelector((state) => state.layer);
  const opacity = useSelector((state) => state.layer.opacity);
  const dispatch = useDispatch();
  return (
    <div>
      <h5 className="mt-2">Administrative</h5>

      <ul className="nav nav-pills flex-column mb-auto">
        {/* <li>
          <input
            onChange={(e) => {
              if (layerVisibility.countryVisible) {
                dispatch(setCountry(false));
              } else {
                dispatch(setCountry(true));
              }
              console.log(e.target.value);
            }}
            className="form-check-input me-1"
            type="checkbox"
            checked={layerVisibility.countryVisible}
            value={layerVisibility.countryVisible}
            aria-label="..."
          />
          Country
        </li> */}
        <li>
          <input
            onChange={(e) => {
              if (layerVisibility.provincesVisible) {
                dispatch(setProvince(false));
              } else {
                dispatch(setProvince(true));
              }
              console.log(e.target.value);
            }}
            className="form-check-input me-1"
            type="checkbox"
            checked={layerVisibility.provincesVisible}
            value={layerVisibility.provincesVisible}
            aria-label="..."
          />
          Province
        </li>
        <li>
          <input
            onChange={(e) => {
              if (layerVisibility.provincesVisible) {
                dispatch(setProvinceOpacity(parseFloat(e.target.value)));
              }
            }}
            value={opacity.province}
            type="range"
            min={0}
            max={1}
            step={0.1}
          />
        </li>
        <li>
          <input
            onChange={(e) => {
              if (layerVisibility.districtsVisible) {
                dispatch(setDistrict(false));
              } else {
                dispatch(setDistrict(true));
              }
              console.log(e.target.value);
            }}
            className="form-check-input me-1"
            type="checkbox"
            checked={layerVisibility.districtsVisible}
            value={layerVisibility.districtsVisible}
            aria-label="..."
          />
          District
        </li>
        <li>
          <input
            onChange={(e) => {
              if (layerVisibility.districtsVisible) {
                dispatch(setDistrictOpacity(parseFloat(e.target.value)));
              }
            
            }}
            value={opacity.district}
            type="range"
            min={0}
            max={1}
            step={0.1}
          />
        </li>
        <li>
          <input
            onChange={(e) => {
              if (layerVisibility.localLevelVisible) {
                dispatch(setLocalLevel(false));
              } else {
                dispatch(setLocalLevel(true));
              }
              console.log(e.target.value);
            }}
            className="form-check-input me-1"
            type="checkbox"
            checked={layerVisibility.localLevelVisible}
            value={layerVisibility.localLevelVisible}
            aria-label="..."
          />
          Local Level
        </li>
        <li>
          <input
            onChange={(e) => {
              if (layerVisibility.localLevelVisible) {
                dispatch(setLocalLevelOpacity(parseFloat(e.target.value)));
              }
            }}
            value={opacity.localLevel}
            type="range"
            min={0}
            max={1}
            step={0.1}
          />
        </li>
      </ul>
    </div>
  );
}

export default LayerList;
