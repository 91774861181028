/* eslint-disable consistent-return */
import { useContext, useEffect } from "react";
// import "openlayers-ext";
import "ol/src/ol.css";
import "ol-ext/dist/ol-ext.css";
// import PrintDialog from "ol-ext/control/PrintDialog";
import PrintDialog from "./CustomPrintDialog";
import CanvasTitle from "ol-ext/control/CanvasTitle";
import CanvasAttribution from "ol-ext/control/CanvasAttribution";
import MapContext from "../MapContext";
import { saveAs } from "file-saver";
import { Style, Text } from "ol/style";
import jsPDF from "jspdf";

const PrintMapControl = ({ title }) => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const printControl = new PrintDialog();
    printControl.setSize("A4");
    printControl.setOrientation("landscape");
    printControl.on(["print", "error"], function (e) {
      if (e.image) {
        if (e.pdf) {
          var pdf = new jsPDF({
            orientation: e.print.orientation,
            unit: e.print.unit,
            format: e.print.size,
          });
          pdf.addImage(
            e.image,
            "JPEG",
            e.print.position[0],
            e.print.position[0],
            e.print.imageWidth,
            e.print.imageHeight
          );
          pdf.save(e.print.legend ? "legend.pdf" : "map.pdf");
        } else {
          e.canvas.toBlob(
            function (blob) {
              var name =
                (e.print.legend ? "legend." : "map.") +
                e.imageType.replace("image/", "");
              saveAs(blob, name);
            },
            e.imageType,
            e.quality
          );
        }
      } else {
        console.warn("No canvas to export");
      }
    });
    var attributionControl = new CanvasAttribution({});
    map.addControl(attributionControl);
    attributionControl.setCanvas(true);
    map.addControl(
      new CanvasTitle({
        title: title ?? "Map Title",
        visible: true,
        style: new Style({
          text: new Text({
            font: '20px "Lucida Grande",Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif',
          }),
        }),
      })
    );

    map.controls.push(printControl);

    return () => map.controls.remove(printControl);
  }, [map, title]);

  return null;
};

export default PrintMapControl;
