import { combineReducers } from "@reduxjs/toolkit";
import tokenReducer from "./login";
import layerReducer from "./layers";
import mapSettingsReducer from "./mapsettings";
import adminReducer from "./adminvalues";
import routeReducer from "./route";
import orgstructReducer from "./orgstruct";

function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    token: tokenReducer,
    layer: layerReducer,
    mapSettings: mapSettingsReducer,
    admin: adminReducer,
    route: routeReducer,
    orgstruct: orgstructReducer,
    ...injectedReducers,
  });
  return rootReducer;
}

export default createReducer;
