/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocationDot,
  faDatabase,
  faCode,
  faCircleInfo,
  faUser,
  faRightFromBracket,
  faPieChart,
  faScrewdriverWrench,
  faDownload,
  faTree,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import NavLink from "../NavLink/NavLink";

function Sidebar() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  useEffect(() => {
    function checkToken() {
      const token = localStorage.getItem("token");

      setToken(token);
    }

    window.addEventListener("storage", checkToken);

    return () => {
      window.removeEventListener("storage", checkToken);
    };
  }, []);

  console.log("token ", token);

  return (
    <div
      id="sidebar-column"
      className="d-flex flex-column flex-shrink-0 p-1 text-white bg-dark"
      style={{ width: "100%", height: "100%" }}
    >
      <Link
        to="/"
        className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
      >
        <span className="fs-4">NTCC Geoportal</span>
      </Link>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <div className="accordion" id="accordionFlushExample1">
            <div className="accordion-item bg-transparent">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button bg-transparent white-text"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="true"
                  aria-controls="flush-collapseOne"
                >
                  Webmaps
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample1"
              >
                <div className="accordion-body pt-1 pb-0 pl-4 pr-0">
                  <NavLink toLink="/" className="nav-link">
                    <FontAwesomeIcon className="me-2" icon={faMapLocationDot} />
                    SDP
                  </NavLink>
                  <NavLink toLink="/webmap-export" className="nav-link">
                    <FontAwesomeIcon className="me-2" icon={faMapLocationDot} />
                    SDP Export
                  </NavLink>
                  <NavLink toLink="/routemap" className="nav-link">
                    <FontAwesomeIcon className="me-2" icon={faMapLocationDot} />
                    RouteMap
                  </NavLink>
                  <NavLink toLink="/linkage-map" className="nav-link">
                    <FontAwesomeIcon className="me-2" icon={faMapLocationDot} />
                    Linkage Map
                  </NavLink>
                  <NavLink toLink="/casemap" className="nav-link">
                    <FontAwesomeIcon className="me-2" icon={faMapLocationDot} />
                    Cases Notified in eTB
                  </NavLink>
                  <NavLink toLink="/buffermap" className="nav-link">
                    <FontAwesomeIcon className="me-2" icon={faMapLocationDot} />
                    Hard To Reach Map
                  </NavLink>
                  <NavLink toLink="/successmap" className="nav-link">
                    <FontAwesomeIcon className="me-2" icon={faMapLocationDot} />
                    Treatment Success Rate
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="nav-item">
          <div className="accordion" id="accordionFlushExample2">
            <div className="accordion-item bg-transparent">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button bg-transparent white-text"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  TB Case Notification
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample2"
              >
                <div className="accordion-body pt-1 pb-0 pl-4 pr-0">
                  <NavLink toLink="/notificationmap" className="nav-link">
                    <FontAwesomeIcon className="me-2" icon={faMapLocationDot} />
                    Rate
                  </NavLink>
                  <NavLink toLink="/notificationnumbermap" className="nav-link">
                    <FontAwesomeIcon className="me-2" icon={faMapLocationDot} />
                    Number
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="nav-item">
          <div className="accordion" id="accordionFlushExample3">
            <div className="accordion-item bg-transparent">
              <h2 className="accordion-header" id="flush-headingThree">
                <button
                  className="accordion-button bg-transparent white-text"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Charts
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample3"
              >
                <div className="accordion-body pt-1 pb-0 pl-4 pr-0">
                  <NavLink toLink="/sdpchart" className="nav-link">
                    <FontAwesomeIcon className="me-2" icon={faPieChart} />
                    SDP
                  </NavLink>
                  <NavLink toLink="/sdpradarchart" className="nav-link">
                    <FontAwesomeIcon className="me-2" icon={faPieChart} />
                    SDP Radar Chart
                  </NavLink>
                  <NavLink toLink="/casechart" className="nav-link">
                    <FontAwesomeIcon className="me-2" icon={faPieChart} />
                    Cases
                  </NavLink>
                  <NavLink toLink="/apicharts" className="nav-link">
                    <FontAwesomeIcon className="me-2" icon={faPieChart} />
                    API Data
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </li>
        {token && (
          <>
            <li className="nav-item">
              <NavLink toLink="/data" className="nav-link">
                <FontAwesomeIcon className="me-2" icon={faDatabase} />
                Data
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink toLink="/rawdata" className="nav-link">
                <FontAwesomeIcon className="me-2" icon={faDatabase} />
                Raw Data
              </NavLink>
            </li>
          </>
        )}
        <li className="nav-item">
          <NavLink toLink="/embed" className="nav-link">
            <FontAwesomeIcon className="me-2" icon={faCode} />
            Embed
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink toLink="/mapdownload" className="nav-link">
            <FontAwesomeIcon className="me-2" icon={faDownload} />
            Map Download
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink toLink="/organizationaltree" className="nav-link">
            <FontAwesomeIcon className="me-2" icon={faTree} />
            Organizational Tree
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink toLink="/aboutus" className="nav-link">
            <FontAwesomeIcon className="me-2" icon={faCircleInfo} />
            About
          </NavLink>
        </li>
      </ul>

      <hr />
      <div className="dropdown">
        <div
          style={{ cursor: "pointer" }}
          className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
          id="dropdownUser1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <FontAwesomeIcon className="px-2" icon={faUser} />

          <strong>Guest</strong>
        </div>
        <ul
          className="dropdown-menu dropdown-menu-dark text-small shadow"
          aria-labelledby="dropdownUser1"
        >
          <li>
            {!token ? (
              <Link className="dropdown-item" to="/login">
                <FontAwesomeIcon className="px-2" icon={faRightFromBracket} />
                Log In
              </Link>
            ) : (
              <Link
                className="dropdown-item"
                to="/login"
                onClick={() => {
                  localStorage.removeItem("token");
                  window.dispatchEvent(new Event("storage"));
                }}
              >
                <FontAwesomeIcon className="px-2" icon={faRightFromBracket} />
                Log out
              </Link>
            )}
          </li>
          <li>
            <a
              className="dropdown-item"
              href="https://gis.ntpmis.gov.np/admin/"
            >
              <FontAwesomeIcon className="px-2" icon={faScrewdriverWrench} />
              Admin Dashboard
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
