import React from "react";
import { EMBED_URL } from "../constants";

function Embed() {
  return (
    <div className="p-5">
      <div className="card m-2">
        <div className="card-header">Embed SDP Map</div>
        <div className="card-body">
          <p className="card-text">{`<iframe height="100%" width="100%" src="${EMBED_URL}sdp" />`}</p>
          <button
            onClick={navigator.clipboard.writeText(
              `<iframe height="100%" width="100%" src="${EMBED_URL}sdp" />`
            )}
            className="btn btn-primary"
          >
            Copy Code
          </button>
        </div>
      </div>
      <div className="card m-2">
        <div className="card-header">Embed eTB cases map</div>
        <div className="card-body">
          <p className="card-text">{`<iframe height="100%" width="100%" src="${EMBED_URL}casemap" />`}</p>
          <button
            onClick={navigator.clipboard.writeText(
              `<iframe height="100%" width="100%" src="${EMBED_URL}casemap" />`
            )}
            className="btn btn-primary"
          >
            Copy Code
          </button>
        </div>
      </div>
      <div className="card m-2">
        <div className="card-header">Embed Treatment Success Rate Map</div>
        <div className="card-body">
          <p className="card-text">{`<iframe height="100%" width="100%" src="${EMBED_URL}successmap" />`}</p>
          <button
            onClick={navigator.clipboard.writeText(
              `<iframe height="100%" width="100%" src="${EMBED_URL}successmap" />`
            )}
            className="btn btn-primary"
          >
            Copy Code
          </button>
        </div>
      </div>
      <div className="card m-2">
        <div className="card-header">Embed TB Notification Rate Map</div>
        <div className="card-body">
          <p className="card-text">{`<iframe height="100%" width="100%" src="${EMBED_URL}notificationrate" />`}</p>
          <button
            onClick={navigator.clipboard.writeText(
              `<iframe height="100%" width="100%" src="${EMBED_URL}notificationrate" />`
            )}
            className="btn btn-primary"
          >
            Copy Code
          </button>
        </div>
      </div>
      <div className="card m-2">
        <div className="card-header">Embed TB Notification Number Map</div>
        <div className="card-body">
          <p className="card-text">{`<iframe height="100%" width="100%" src="${EMBED_URL}notificationnumber" />`}</p>
          <button
            onClick={navigator.clipboard.writeText(
              `<iframe height="100%" width="100%" src="${EMBED_URL}notificationnumber" />`
            )}
            className="btn btn-primary"
          >
            Copy Code
          </button>
        </div>
      </div>
    </div>
  );
}

export default Embed;
