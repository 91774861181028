import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { BASE_URL } from "../../constants";
import { deepEqual } from "../../utils/deepEqual";
import { arrayReducer } from "../../utils/arrayReducer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HFData = ({ params }) => {
  const chartRef = useRef();
  const handleDownload = () => {
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = chartRef.current.toBase64Image("image/png", 1); // Here is the magic
    link.click();
  };
  const admin = useSelector((state) => state.admin);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Health Facilities",
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  useEffect(() => {
    fetch(BASE_URL + "hfdatabyadmin/?" + new URLSearchParams(params)).then(
      (res) =>
        res.json().then((result) => {
          let labels = [];
          if (params.local_level_id != null) {
            labels = result.map((e) => `Ward ${e.ward}`);
          } else if (params.district_id != null) {
            labels = result.map(
              (e) =>
                arrayReducer(admin.local_levels, "id")[
                  e.local_level_id
                ].name_en.split(" ")[0]
            );
          } else if (params.province_id != null) {
            labels = result.map(
              (e) => arrayReducer(admin.districts, "id")[e.district_id].name_en
            );
          } else {
            labels = result.map(
              (e) => arrayReducer(admin.provinces, "id")[e.province_id]?.name_en
            );
          }
          const datasets = [];
          if (params.drVisible) {
            datasets.push({
              label: "DR Center",
              data: result.map((e) => e.dr_count),
              backgroundColor: "rgb(75, 192, 192)",
            });
          }
          if (params.sdrVisible) {
            datasets.push({
              label: "SDR Center",
              data: result.map((e) => e.sdr_count),
              backgroundColor: "rgb(55, 99, 132)",
            });
          }
          if (params.mcVisible) {
            datasets.push({
              label: "Microcopic Center",
              data: result.map((e) => e.mc_count),
              backgroundColor: "rgb(255, 99, 132)",
            });
          }
          if (params.geneVisible) {
            datasets.push({
              label: "GeneX Center",
              data: result.map((e) => e.gene_count),
              backgroundColor: "rgb(255, 162, 235)",
            });
          }
          if (params.dotsVisible) {
            datasets.push({
              label: "Dots",
              data: result.map((e) => e.dots_count),
              backgroundColor: "rgb(53, 162, 235)",
            });
          }
          if (params.hstlVisible) {
            datasets.push({
              label: "Hostel Home",
              data: result.map((e) => e.hstl_count),
              backgroundColor: "rgb(53, 255, 235)",
            });
          }
          if (params.cltrVisible) {
            datasets.push({
              label: "Culture LPA",
              data: result.map((e) => e.cltr_lpa_count),
              backgroundColor: "rgb(53, 162, 99)",
            });
          }
          const data = {
            labels,
            datasets,
          };
          setChartData(data);
        })
    );

    return () => {};
  }, [admin.districts, admin.local_levels, admin.provinces, params]);

  return <>

      <button className="btn btn-primary" onClick={handleDownload}>Export Chart</button>
    <Bar ref={chartRef} options={options} data={chartData} /></>;
};

function areEqual(prevProps, nextProps) {
  return deepEqual(prevProps, nextProps);
}

export default React.memo(HFData, areEqual);
