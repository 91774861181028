/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import AddData from "../components/Data/AddData";
import { BASE_URL } from "../constants";
import useIntersectionObserver from "../Hooks/IntersectionObserver";
import Loader from "../assets/smallloader.gif";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}
export default function RawData() {
  const { province_ids, district_ids, local_level_ids } = useSelector(
    (state) => state.admin.adminFilter
  );

  const [isOpen, setIsOpen] = useState(false);
  const [token] = useState(localStorage.getItem("token"));

  const [state, setState] = useState({
    dataLoading: true,
    backupDataforFilter: [],
    allData: [],
    paginatedData: {
      data: [],
      nextPage: null,
    },
    formfields: {
      id: null,
      sdc_name: "",
      hf_type: "",
      is_dr: false,
      is_sdr: false,
      is_gene: false,
      is_mc: false,
      is_dots: false,
      is_hostel_home: false,
      is_cltr_lpa: false,
      is_home: false,
      location: [84.30633, 28.38311],
      province_id: null,
      district_id: null,
      local_level_id: null,
      code: 0,
      ntp_id: "",
      ward: 0,
    },
  });

  const filterfunc = (da) => {
    return (
      (province_ids.length > 0
        ? province_ids.includes(da.province_id_id)
        : true) &&
      (district_ids.length > 0
        ? district_ids.includes(da.district_id_id)
        : true) &&
      (local_level_ids.length > 0
        ? local_level_ids.includes(da.local_level_id_id)
        : true)
    );
  };

  useEffect(() => {
    const filtered = state.backupDataforFilter.filter(filterfunc);

    setState({
      ...state,
      allData: [...filtered],
      paginatedData: {
        data: paginate([...filtered], 20, 1),
        nextPage: filtered.length < 20 ? null : 2,
      },
    });
  }, [province_ids, district_ids, local_level_ids]);
  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const [lastElementInView, LoadMoreRef, ParentLoadMoreRef] =
    useIntersectionObserver();

  const handleLoadMore = () => {
    if (!state.paginatedData.nextPage) return "";
    setState({
      ...state,
      paginatedData: {
        data: [
          ...state.paginatedData.data,
          ...paginate(state.allData, 20, state.paginatedData.nextPage),
        ],
        nextPage: state.paginatedData.nextPage + 1,
      },
    });
  };

  const onEdit = (row) => {
    setState({
      ...state,
      formfields: {
        ntp_id: row.ntp_id,
        province_id: row.province_id_id,
        district_id: row.district_id_id,
        local_level_id: row.local_level_id_id,
        location: row.location?.coordinates ?? [84.30633, 28.38311],
        sdc_name: row.hf_name,
      },
    });
    toggleModal();
  };

  useEffect(() => {
    if (lastElementInView) {
      handleLoadMore();
    }
  }, [lastElementInView]);

  useEffect(() => {
    fetch(BASE_URL + "rawdata/", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")} `,
      },
    }).then((res) => {
      res.json().then((result) => {
        setState({
          ...state,
          dataLoading: false,
          backupDataforFilter: result,
          allData: result.filter(filterfunc),
          paginatedData: {
            data: paginate(result, 20, 1),
            nextPage: 2,
          },
        });
      });
    });
  }, []);

  return (
    <div className="container">
      {state.dataLoading && (
        <img alt="loader" src={Loader} style={{ width: 50, height: 50 }} />
      )}
      <div className="table-wrapper is-scroll" ref={ParentLoadMoreRef}>
        <table className="table">
          <thead>
            <tr>
              <td></td>
              {Object.keys(state.paginatedData.data[0] || {}).map((header) => {
                return <th scope="col">{header}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {state.paginatedData.data.map((row) => {
              return (
                <tr>
                  {token && (
                    <>
                      <td
                        onClick={() => onEdit(row)}
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon className="px-2" icon={faAdd} />
                      </td>
                    </>
                  )}
                  {Object.keys(state.paginatedData.data[0]).map((header) => {
                    return <td>{row[header]?.toString()}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot ref={LoadMoreRef}></tfoot>
        </table>
      </div>
      {isOpen && (
        <AddData toggleModal={toggleModal} state={state} setState={setState} />
      )}
    </div>
  );
}
