import React, { useEffect, useContext } from "react";
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "../../../store";
import MapContext from "../MapContext";
import { SidePanel as SP } from "ol-side-panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup, faGears } from "@fortawesome/free-solid-svg-icons";
import LayerList from "../../LayersList";
import MapSettings from "../../MapSettings/MapSettings";

function OLSidePanel() {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;
    const sidePanel = new SP();
    map.controls.push(sidePanel);

    const layersPane = sidePanel.definePane({
      paneId: "layers",
      name: "Layers",
      icon: ReactDOMServer.renderToString(
        <div>
          <FontAwesomeIcon className="px-2" icon={faLayerGroup} />
        </div>
      ),
    });
    const layersGreeting = document.createElement("div");
    const layerPane = ReactDOM.createRoot(layersGreeting);
    layerPane.render(
      <Provider store={store}>
        <LayerList />
      </Provider>
    );

    layersPane.addWidgetElement(layersGreeting);
    // const settingsPane = sidePanel.definePane({
    //   paneId: "Settings",
    //   name: "Settings",
    //   weight: 101,
    //   icon: ReactDOMServer.renderToString(
    //     <FontAwesomeIcon className="px-2" icon={faGears} />
    //   ),
    // });

    // const settingsGreeting = document.createElement("div");
    // const settingPane = ReactDOM.createRoot(settingsGreeting);
    // settingPane.render(
    //   <Provider store={store}>
    //     <MapSettings />
    //   </Provider>
    // );
    // settingsPane.addWidgetElement(settingsGreeting);

    return () => {
      map.controls.remove(sidePanel);
    };
  }, [map]);

  return null;
}

export default OLSidePanel;
