import { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDistrictIds,
  setLocalLevelIds,
  setProvinceIds,
} from "../../../slices/adminvalues";
import "ol/ol.css";
import Overlay from "ol/Overlay";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON";
import MVT from "ol/format/MVT";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import { Fill, Style, Text, Stroke, Circle } from "ol/style";
import MapContext from "../MapContext";
import { BASE_URL } from "../../../constants";
import { transformExtent } from "ol/proj";
import { bbox } from "@turf/turf";
import SourceControl from "../Controls/SourceControl";

const sourceControl = new SourceControl({
  sourceText: "Data Source HMIS",
});
export function provinceStyleFunction(feature, resolution) {
  const sum = feature.getProperties()["notification_number"];

  return new Style({
    image: new Circle({
      radius: sum / 100,
      fill: new Fill({
        color: "#fa000088",
      }),
      stroke: new Stroke({
        color: "#fa0000ff",
        width: 2,
      }),
    }),
  });
}
export function districtStyleFunction(feature, resolution) {
  const sum = feature.getProperties()["notification_number"];
  return new Style({
    image: new Circle({
      radius: sum / 20,
      fill: new Fill({
        color: "#fa000088",
      }),
      stroke: new Stroke({
        color: "#fa0000ff",
        width: 2,
      }),
    }),
  });
}
export function localLevelStyleFunction(feature, resolution) {
  const sum = feature.getProperties()["notification_number"];
  return new Style({
    image: new Circle({
      radius: sum / 5,
      fill: new Fill({
        color: "#fa000088",
      }),
      stroke: new Stroke({
        color: "#fa0000ff",
        width: 2,
      }),
    }),
  });
}
const provinceCasesSource = new VectorSource({});
const provinceCasesLayer = new VectorLayer({
  source: provinceCasesSource,
  zIndex: 111,
  style: provinceStyleFunction,
});
const districtCasesSource = new VectorSource({});
const districtCasesLayer = new VectorLayer({
  source: districtCasesSource,
  zIndex: 222,
  style: districtStyleFunction,
});
const localLevelCasesSource = new VectorSource({});
const localLevelCasesLayer = new VectorLayer({
  source: localLevelCasesSource,
  zIndex: 222,
  style: localLevelStyleFunction,
});
const provinceSource = new VectorTileSource({
  projection: "EPSG:3857",
  maxZoom: 288,
  format: new MVT({}),
});
const provinceLayer = new VectorTileLayer({
  renderMode: "vector",
  className: "province-layer",
  source: provinceSource,
  zIndex: 11,
  style: new Style({
    stroke: new Stroke({
      color: "#000000",
      width: 1.5,
    }),
    fill: new Fill({
      color: "#00000000",
    }),
  }),
});
const districtSource = new VectorTileSource({
  projection: "EPSG:3857",
  maxZoom: 288,
  format: new MVT({}),
});
const districtLayer = new VectorTileLayer({
  className: "district-layer",
  source: districtSource,
  zIndex: 22,
  style: new Style({
    stroke: new Stroke({
      color: "#000000",
      width: 1,
    }),
    fill: new Fill({
      color: "#00000000",
    }),
  }),
});
const localLevelSource = new VectorTileSource({
  projection: "EPSG:3857",
  maxZoom: 288,
  format: new MVT({}),
});
const localLevelLayer = new VectorTileLayer({
  className: "local-level-layer",
  source: localLevelSource,
  zIndex: 33,
  style: new Style({
    stroke: new Stroke({
      color: "#000000",
      width: 0.5,
    }),
    fill: new Fill({
      color: "#00000000",
    }),
  }),
});
export default function TBNotificationNumberLayer() {
  const dispatch = useDispatch();
  const layerVisibility = useSelector((state) => state.layer);
  const filter = useSelector((state) => state.admin.adminFilter);
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;
    map.addLayer(provinceCasesLayer);
    map.addLayer(districtCasesLayer);
    map.addLayer(localLevelCasesLayer);
    map.addLayer(provinceLayer);
    map.addLayer(districtLayer);
    map.addLayer(localLevelLayer);
    map.addControl(sourceControl);

    return () => {
      map.removeLayer(provinceCasesLayer);
      map.removeLayer(districtCasesLayer);
      map.removeLayer(localLevelCasesLayer);
      map.removeLayer(provinceLayer);
      map.removeLayer(districtLayer);
      map.removeLayer(localLevelLayer);
      map.removeControl(sourceControl);
    };
  }, [map]);
  useEffect(() => {
    if (!map) return;
    if (filter.local_level_ids.length) {
      fetch(
        `${BASE_URL}locallevelextent/?local_level_ids=${filter.local_level_ids}`
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    } else if (filter.district_ids.length) {
      fetch(`${BASE_URL}districtextent/?district_ids=${filter.district_ids}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    } else if (filter.province_ids.length) {
      fetch(`${BASE_URL}provinceextent/?province_ids=${filter.province_ids}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    } else {
      fetch(`${BASE_URL}provinceextent/`)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    }
  }, [filter.local_level_ids, filter.district_ids, filter.province_ids, map]);
  useEffect(() => {
    if (!map) return;
    const container = document.getElementById("popup");
    const content = document.getElementById("popup-content");
    let feature = null;

    const overlay = new Overlay({
      stopEvent: true,
      element: container,
      autoPan: false,
      autoPanAnimation: {
        duration: 250,
      },
    });
    map.addOverlay(overlay);

    map.on("singleclick", (e) => {
      const { pixel } = e;
      localLevelLayer.getFeatures(pixel).then((clickedFeatures) => {
        if (clickedFeatures.length && layerVisibility.localLevelVisible) {
          const local_level_id = clickedFeatures[0].getProperties()["id"];
          dispatch(setLocalLevelIds([local_level_id]));
        } else {
          districtLayer.getFeatures(pixel).then((clickedFeatures) => {
            if (clickedFeatures.length && layerVisibility.districtsVisible) {
              const district_id = clickedFeatures[0].getProperties()["id"];
              dispatch(setDistrictIds([district_id]));
            } else {
              provinceLayer.getFeatures(pixel).then((clickedFeatures) => {
                if (
                  clickedFeatures.length &&
                  layerVisibility.provincesVisible
                ) {
                  const province_id = clickedFeatures[0].getProperties()["id"];
                  dispatch(setProvinceIds([province_id]));
                }
              });
            }
          });
        }
      });
    });

    map.on("pointermove", (e) => {
      const { pixel, coordinate } = e;
      localLevelCasesLayer.getFeatures(pixel).then((clickedFeatures) => {
        if (clickedFeatures.length) {
          const local_level_sum =
            clickedFeatures[0].getProperties()["notification_number"];
          const name = clickedFeatures[0].getProperties()["name_en"];
          content.innerHTML = `
                  <table style="width:100%;border-collapse:unset;">
                  <tr>
                      <td>Name: </td>
                      <td><b>${name}</b></td>
                    </tr>
                    <tr>
                      <td>TB Case Notified: </td>
                      <td><b>${local_level_sum}</b></td>
                    </tr>
                  </table>
                  `;
          if (feature !== clickedFeatures[0]) {
            overlay.setPosition(coordinate);
            feature = clickedFeatures[0];
          }
        } else {
          districtCasesLayer.getFeatures(pixel).then((clickedFeatures) => {
            if (clickedFeatures.length) {
              const district_sum =
                clickedFeatures[0].getProperties()["notification_number"];
              const name = clickedFeatures[0].getProperties()["name_en"];
              content.innerHTML = `
                  <table style="width:100%;border-collapse:unset;">
                  <tr>
                      <td>Name: </td>
                      <td><b>${name}</b></td>
                    </tr>
                    <tr>
                      <td>TB Case Notified: </td>
                      <td><b>${district_sum}</b></td>
                    </tr>
                  </table>
                  `;
              if (feature !== clickedFeatures[0]) {
                overlay.setPosition(coordinate);
                feature = clickedFeatures[0];
              }
            } else {
              provinceCasesLayer.getFeatures(pixel).then((clickedFeatures) => {
                if (clickedFeatures.length) {
                  const province_sum =
                    clickedFeatures[0].getProperties()["notification_number"];
                  const name = clickedFeatures[0].getProperties()["name_en"];
                  content.innerHTML = `
                  <table style="width:100%;border-collapse:unset;">
                    <tr>
                      <td>Name: </td>
                      <td><b>${name}</b></td>
                    </tr>
                    <tr>
                      <td>TB Case Notified: </td>
                      <td><b>${province_sum}</b></td>
                    </tr>
                  </table>
                  `;
                  if (feature !== clickedFeatures[0]) {
                    overlay.setPosition(coordinate);
                    feature = clickedFeatures[0];
                  }
                } else {
                  overlay.setPosition(undefined);
                  feature = null;
                }
              });
            }
          });
        }
      });
    });

    return () => {
      map.removeOverlay(overlay);
    };
  }, [
    dispatch,
    layerVisibility.districtsVisible,
    layerVisibility.localLevelVisible,
    layerVisibility.provincesVisible,
    map,
  ]);

  useEffect(() => {
    provinceLayer.setVisible(layerVisibility.provincesVisible);
  }, [layerVisibility.provincesVisible]);
  useEffect(() => {
    districtLayer.setVisible(layerVisibility.districtsVisible);
  }, [layerVisibility.districtsVisible]);
  useEffect(() => {
    localLevelLayer.setVisible(layerVisibility.localLevelVisible);
  }, [layerVisibility.localLevelVisible]);

  useEffect(() => {
    if (!map) return;
    if (filter.fiscal_year_id) {
      if (filter.local_level_ids.length)
        fetch(
          `${BASE_URL}locallevelgeojsonwithvalues/?fiscal_year=${filter.fiscal_year_id}&locallevel_ids=${filter.local_level_ids}`
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            provinceCasesSource.clear();
            districtCasesSource.clear();
            localLevelCasesSource.clear();
            localLevelCasesSource.addFeatures(
              new GeoJSON({
                featureProjection: "EPSG:3857",
              }).readFeatures(json)
            );
          });
      else if (filter.district_ids.length)
        fetch(
          `${BASE_URL}locallevelgeojsonwithvalues/?fiscal_year=${filter.fiscal_year_id}&district_ids=${filter.district_ids}`
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            provinceCasesSource.clear();
            districtCasesSource.clear();
            localLevelCasesSource.clear();
            localLevelCasesSource.addFeatures(
              new GeoJSON({
                featureProjection: "EPSG:3857",
              }).readFeatures(json)
            );
          });
      else if (filter.province_ids.length)
        fetch(
          `${BASE_URL}districtgeojsonwithvalues/?fiscal_year=${filter.fiscal_year_id}&province_ids=${filter.province_ids}`
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            provinceCasesSource.clear();
            districtCasesSource.clear();
            localLevelCasesSource.clear();
            districtCasesSource.addFeatures(
              new GeoJSON({
                featureProjection: "EPSG:3857",
              }).readFeatures(json)
            );
          });
      else
        fetch(
          `${BASE_URL}provincegeojsonwithvalues/?fiscal_year=${filter.fiscal_year_id}`
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            localLevelCasesSource.clear();
            districtCasesSource.clear();
            provinceCasesSource.clear();
            provinceCasesSource.addFeatures(
              new GeoJSON({
                featureProjection: "EPSG:3857",
              }).readFeatures(json)
            );
          });
    }
    return () => {};
  }, [
    map,
    filter.local_level_ids,
    filter.district_ids,
    filter.province_ids,
    filter.fiscal_year_id,
  ]);
  useEffect(() => {
    provinceSource.clear();
    provinceSource.setUrl(`${BASE_URL}provincevt/{z}/{x}/{y}?fiscal_year=${1}`);
  }, []);

  useEffect(() => {
    districtSource.clear();
    districtSource.setUrl("");
    if (filter.province_ids.length) {
      districtSource.setUrl(
        `${BASE_URL}districtvt/{z}/{x}/{y}?fiscal_year=${1}&province_ids=${
          filter.province_ids
        }`
      );
    }
  }, [filter.province_ids]);

  useEffect(() => {
    if (!map) return;
    localLevelSource.clear();
    localLevelSource.setUrl("");

    if (filter.local_level_ids.length) {
      localLevelSource.setUrl(
        `${BASE_URL}locallevelvt/{z}/{x}/{y}?fiscal_year=${1}&locallevel_ids=${
          filter.local_level_ids
        }`
      );
    } else if (filter.district_ids.length) {
      localLevelSource.setUrl(
        `${BASE_URL}locallevelvt/{z}/{x}/{y}?fiscal_year=${1}&district_ids=${
          filter.district_ids
        }`
      );
    }
    return () => {};
  }, [map, filter.local_level_ids, filter.district_ids]);
  return (
    <div id="popup" className="ol-popup">
      <div id="popup-content" className="is-overflow" />
    </div>
  );
}
