import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Map from "../components/MapOpenlayers/Map";
import { Layers } from "../components/MapOpenlayers/Layers";
import LayerSwitcherControl from "../components/MapOpenlayers/Controls/LayerSwitcherControl";
import { fromLonLat } from "ol/proj";
import {
  Controls,
  FullScreenControl,
} from "../components/MapOpenlayers/Controls";
import ZoomControl from "../components/MapOpenlayers/Controls/ZoomControl";
import OLSidePanel from "../components/MapOpenlayers/SidePanel/SidePanelWebmap";
import Legend from "../components/MapOpenlayers/Legend/Legend";
import Scale from "../components/MapOpenlayers/Scale/Scale";
import Popup from "../components/MapOpenlayers/Popup";
import { BASE_URL } from "../constants";
import PrintMapControl from "../components/MapOpenlayers/PrintMap";
import { setHFMaster } from "../slices/adminvalues";
import SDPLayerForExport from "../components/MapOpenlayers/Layers/SDPLayerForExport";
import { osm } from "../components/MapOpenlayers/Layers/BaseLayers";

const mapCenter = fromLonLat([85, 28.5]);
const mapZoom = 7.2;

export default function WebMapForExport() {
  const dispatch = useDispatch();
  useEffect(() => {
    fetch(`${BASE_URL}hfdata/geojsonbytype?type=dr`).then((response) =>
      response
        .json()
        .then((result) => dispatch(setHFMaster({ dr: result.features })))
    );
    fetch(`${BASE_URL}hfdata/geojsonbytype?type=sdr`).then((response) =>
      response
        .json()
        .then((result) => dispatch(setHFMaster({ sdr: result.features })))
    );
    fetch(`${BASE_URL}hfdata/geojsonbytype?type=gene`).then((response) =>
      response
        .json()
        .then((result) => dispatch(setHFMaster({ gene: result.features })))
    );
    fetch(`${BASE_URL}hfdata/geojsonbytype?type=dots`).then((response) =>
      response
        .json()
        .then((result) => dispatch(setHFMaster({ dots: result.features })))
    );
    fetch(`${BASE_URL}hfdata/geojsonbytype?type=hostel_home`).then((response) =>
      response
        .json()
        .then((result) => dispatch(setHFMaster({ hstl: result.features })))
    );
    fetch(`${BASE_URL}hfdata/geojsonbytype?type=home`).then((response) =>
      response
        .json()
        .then((result) => dispatch(setHFMaster({ home: result.features })))
    );
    fetch(`${BASE_URL}hfdata/geojsonbytype?type=mc`).then((response) =>
      response
        .json()
        .then((result) => dispatch(setHFMaster({ mc: result.features })))
    );
    fetch(`${BASE_URL}hfdata/geojsonbytype?type=cltr_lpa`).then((response) =>
      response
        .json()
        .then((result) => dispatch(setHFMaster({ cltr: result.features })))
    );
  }, [dispatch]);
  const mapSettings = useSelector((state) => state.mapSettings);
  return (
    <div className="row gx-0 gy-0">
      <div className="col-md-12 position-relative">
        <Map
          id="map"
          className="map"
          cssStyle={{ height: "100%", width: "100%" }}
          center={mapCenter}
          zoom={mapZoom}
        >
          <Legend />
          <Layers>
            <SDPLayerForExport />
          </Layers>
          <Popup />
          <Controls>
            {mapSettings.scaleSettings.visible && <Scale />}
            <PrintMapControl title="TB Service Delivery Points" />
            <OLSidePanel />
            <FullScreenControl />
            <ZoomControl />
          </Controls>
        </Map>
      </div>
    </div>
  );
}
