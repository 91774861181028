import { useEffect, useContext } from "react";

import LegendControl from "ol-ext/control/Legend";
import OLLegend from "ol-ext/legend/Legend";
import MapContext from "../MapContext";
import { Style, Fill } from "ol/style";
import { buffercolorramp } from "../Layers/BufferVT";

function Legend() {
  const { map } = useContext(MapContext);
  useEffect(() => {
    if (!map) return;
    var legend = new OLLegend({
      title: "Legend",
      size: [16, 16],
      margin: 2,
    });
    var legendCtrl = new LegendControl({
      legend: legend,
    });
    legend.addItem({
      title: "Buffer Distance (Km)",
    });
    legend.addItem({
      title: "0-2",
      typeGeom: "Polygon",
      style: new Style({
        fill: new Fill({
          color: buffercolorramp[0],
        }),
      }),
    });
    legend.addItem({
      title: "2-5",
      typeGeom: "Polygon",
      style: new Style({
        fill: new Fill({
          color: buffercolorramp[1],
        }),
      }),
    });
    legend.addItem({
      title: "5-10",
      typeGeom: "Polygon",
      style: new Style({
        fill: new Fill({
          color: buffercolorramp[2],
        }),
      }),
    });
    map.addControl(legendCtrl);

    return () => {
      map.controls.remove(legendCtrl);
    };
  }, [map]);

  return null;
}

export default Legend;
