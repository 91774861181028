/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';
import OLVectorLayer from 'ol/layer/Vector';
import { toLonLat, transform, transformExtent } from 'ol/proj';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
// import Feature from 'ol/Feature';
// import Point from 'ol/geom/Point';
import LayerContext from '../LayerContext';
import MapContext from '../MapContext';

const highlightStyle = new Style({
  fill: new Fill({
    color: 'rgba(255,255,255,0.7)',
  }),
  stroke: new Stroke({
    color: '#3399CC',
    width: 3,
  }),
});
const VectorLayer = ({
  source: layerSource,
  style,
  zIndex = 0,
  zoomToExtentFn,
  visibleOnMap = true,
  children,
}) => {
  const { map, popupContentRef, overlay, setPopupContent } = useContext(
    MapContext,
  );
  // const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;
    const vectorLayer = new OLVectorLayer({
      source: layerSource,
      style,
    });

    if (visibleOnMap) {
      map.addLayer(vectorLayer);
    } else {
      map.removeLayer(vectorLayer);
    }
    vectorLayer.setZIndex(zIndex);
    // const selected = null;
    // map.on('singleclick', evt => {
    //   let hit = false;

    //   let stateFeature = map.forEachFeatureAtPixel(
    //     evt.pixel,
    //     // eslint-disable-next-line consistent-return
    //     () => {
    //       hit = true;
    //     },
    //     layer => layer === vectorLayer,
    //   );
    //   if (stateFeature) {
    //     const extent = stateFeature.getGeometry().getExtent();
    //     map.getView().fitExtent(extent, map.getSize());
    //   } else {
    //     stateFeature = null;
    //   }
    // });
    // map.on('singleclick', e => {
    //   // let hit = false;
    //   const hit = map.forEachFeatureAtPixel(
    //     e.pixel,
    //     () => {
    //       return true;
    //     },
    //     layer => {
    //       console.log(layer, 'layer');
    //       console.log(vectorLayer, 'vectorlayer');
    //       return layer === vectorLayer;
    //     },
    //   );
    //   console.log(hit, 'hit');
    //   // feature.changed();
    // });
    // searchResultSource.on('addfeature', function () {
    const sourceExtent = layerSource.getExtent();
    // const layerExtent = vectorLayer.getExtent();
    // console.log(source, 'source');
    if (zoomToExtentFn) {
      zoomToExtentFn(map, layerSource, sourceExtent);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (map) {
        map.removeLayer(vectorLayer);
      }
    };
  }, [map, visibleOnMap, zIndex]);

  return (
    // <LayerContext.Provider value={{ layerSource }}>
    <div>{children}</div>
    // </LayerContext.Provider>
  );
};
export default VectorLayer;
