import React from "react";
import Topbar from "../components/Topbar/Topbar";

function addTopBar(child) {
  return (
    <>
      <div className="row gx-0 gy-0">
        <Topbar />
      </div>
      <div className="row flex-grow-1 gx-0 gy-0">{child}</div>
    </>
  );
}

export default addTopBar;
