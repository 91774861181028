import LayerTile from "ol/layer/Tile";
import { BingMaps, OSM, XYZ } from "ol/source";

const osm = (visible = false) => {
  return new LayerTile({
    title: "None",
    type: "base",
    visible,
    source: null,
  });
};
const topoMap = (visible = true) => {
  return new LayerTile({
    title: "Topo Map",
    type: "base",
    visible,
    source: new XYZ({
      attributions:
        'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
        'rest/services/World_Topo_Map/MapServer">ArcGIS</a>',
      url:
        "https://server.arcgisonline.com/ArcGIS/rest/services/" +
        "World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
      crossOrigin: "Anonymous",
      layer: "topoMap",
      maxZoom: 19,
    }),
  });
};
const satelliteMap = (visible = false) => {
  return new LayerTile({
    title: "Satellite",
    type: "base",
    visible,
    source: new BingMaps({
      key: "AoTlmaazzog43ImdKts9HVztFzUI4PEOT0lmo2V4q7f20rfVorJGAgDREKmfQAgd",
      imagerySet: "Aerial",
      maxZoom: 19,
      // use maxZoom 19 to see stretched tiles instead of the BingMaps
      // "no photos at this zoom level" tiles
      // maxZoom: 19
    }),
  });
};
// const satelliteMap = new LayerTile({
//   title: 'Satellite',
//   type: 'base',
//   visible: false,
//   source: new XYZ({
//     attributions: [
//       'Powered by Esri',
//       'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community',
//     ],
//     attributionsCollapsible: false,
//     url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
//     subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
//     maxZoom: 23,
//   }),
// });
export { osm, satelliteMap, topoMap };
