import { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "ol/ol.css";
import { fromLonLat, transform } from "ol/proj";
import { transformExtent } from "ol/proj";
import { bbox } from "@turf/turf";
import GeoJSON from "ol/format/GeoJSON";
import { Style, Icon, Stroke } from "ol/style";
import MapContext from "../MapContext";
import dots from "../../../assets/icons/DOTS.png";
import drcenter from "../../../assets/icons/DRcenter.png";
import geneX from "../../../assets/icons/GeneX.png";
import lpa from "../../../assets/icons/LPA.png";
import mc from "../../../assets/icons/mc.png";
import hstl from "../../../assets/icons/Hstl.png";
import home from "../../../assets/icons/Home.png";
import subdr from "../../../assets/icons/SubDR.png";
import VectorSource from "ol/source/Vector";
import Vector from "ol/layer/Vector";
import { Overlay } from "ol";

const vectorSource = new VectorSource({});
const vectorLayer = new Vector({
  zIndex: 111,
  source: vectorSource,
});
const linkLineSource = new VectorSource({});
const linkLineLayer = new Vector({
  zIndex: 111,
  source: linkLineSource,
  style: new Style({
    stroke: new Stroke({
      color: "blue",
      width: 2,
    })
  }),
});
const linkNodeSource = new VectorSource({});
const linkNodeLayer = new Vector({
  zIndex: 111,
  source: linkNodeSource,
});
function getIconStyle(properties, xAnchor, yAnchor) {
  let icon;
  if ("is_dr" in properties) {
    icon = new Icon({
      src: drcenter,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  } else if ("is_sdr" in properties) {
    icon = new Icon({
      src: subdr,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  } else if ("is_mc" in properties) {
    icon = new Icon({
      src: mc,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  } else if ("is_dots" in properties) {
    icon = new Icon({
      src: dots,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  } else if ("is_gene" in properties) {
    icon = new Icon({
      src: geneX,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  } else if ("is_cltr_lpa" in properties) {
    icon = new Icon({
      src: lpa,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  } else if ("is_hostel_home" in properties) {
    icon = new Icon({
      src: hstl,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  } else if ("is_home" in properties) {
    icon = new Icon({
      src: home,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  }
  return new Style({
    image: icon,
  });
}
export default function LinkLayer({ linkageData }) {
  console.log(linkageData, "linkageData");
  const { map } = useContext(MapContext);
  const [linkNodesData, setLinkNodesData] = useState([]);
  const [linkLinesData, setLinkLinesData] = useState([]);
  useEffect(() => {
    if (!map) return;
    const container = document.getElementById("popup");
    const closer = document.getElementById("popup-closer");
    const content = document.getElementById("popup-content");
    const contentNode = ReactDOM.createRoot(content);

    const overlay = new Overlay({
      stopEvent: true,
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });
    closer.onclick = function() {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };
    map.addOverlay(overlay);
    map.on("singleclick", (e) => {
      const { pixel, coordinate } = e;
      const [lon, lat] = transform(coordinate, "EPSG:3857", "EPSG:4326");

      vectorLayer.getFeatures(pixel).then((clickedFeatures) => {
        if (clickedFeatures.length) {
          const name = clickedFeatures[0].getProperties()["sdc_name"];
          const coordinates = clickedFeatures[0].getGeometry().getCoordinates();
          const transformCoordinates = transform(coordinates, "EPSG:3857", "EPSG:4326");
          contentNode.render(
            <>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "unset",
                }}
              >
                <tbody>
                  <tr>
                    <td>Name: </td>
                    <td>
                      <b>{name}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                onClick={() => {
                  setLinkNodesData(clickedFeatures[0].getProperties().links);
                  setLinkLinesData(clickedFeatures[0].getProperties().links.map((link) => {
                    return {
                      type: "Feature",
                      geometry: {
                        type: "LineString",
                        coordinates: [transformCoordinates, link.geometry.coordinates],
                      },
                    };
                  }));
                }}
                type="button"
                className="btn btn-primary"
              >
                Show Links
              </button>
            </>,
          );
          overlay.setPosition(coordinate);
        }
      });

      linkNodeLayer.getFeatures(pixel).then((clickedFeatures) => {
        if (clickedFeatures.length) {
          const name = clickedFeatures[0].getProperties()["sdc_name"];
          contentNode.render(
            <>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "unset",
                }}
              >
                <tbody>
                  <tr>
                    <td>Name: </td>
                    <td>
                      <b>{name}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>,
          );
          overlay.setPosition(coordinate);
        }
      });

    });

    return () => { };
  }, [map]);

  useEffect(() => {
    if (!map) return;
    const geojsonFormat = new GeoJSON({
      featureProjection: "EPSG:3857",
    });
    const features = geojsonFormat.readFeatures({
      type: "FeatureCollection",
      features: linkageData,
    });
    vectorLayer.setStyle((feature) => {
      return getIconStyle(feature.getProperties());
    });
    vectorSource.clear();
    vectorSource.addFeatures(features);
    map.addLayer(vectorLayer);
    return () => {
      map.removeLayer(vectorLayer);
    };
  }, [linkageData, map]);

  useEffect(() => {
    if (!map) return;
    const geojsonFormat = new GeoJSON({
      featureProjection: "EPSG:3857",
    });
    const features = geojsonFormat.readFeatures({
      type: "FeatureCollection",
      features: linkNodesData,
    });
    const linkFeatures = geojsonFormat.readFeatures({
      type: "FeatureCollection",
      features: linkLinesData,
    });
    linkLineSource.clear();
    linkNodeSource.clear();
    linkNodeSource.addFeatures(features);
    linkLineSource.addFeatures(linkFeatures);
    linkNodeLayer.setStyle((feature) => {
      return getIconStyle(feature.getProperties());
    });
    map.addLayer(linkLineLayer);
    map.addLayer(linkNodeLayer);
    /* if(linkNodesData.length === 0) return;
    const extent = transformExtent(
      bbox({
        type: "FeatureCollection",
        features: linkNodesData,
      }),
      "EPSG:4326",
      "EPSG:3857",
    );
    map.getView().fit(extent, map.getSize()); */

    return () => {
      map.removeLayer(linkLineLayer);
      map.removeLayer(linkNodeLayer);
    };
  }, [map, linkNodesData, linkLinesData]);

  return (
    <div id="popup" className="ol-popup">
      <a href={() => { }} id="popup-closer" className="ol-popup-closer" />
      <div id="popup-content" className="is-overflow" />
    </div>
  );
}
