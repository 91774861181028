import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { BASE_URL, NTPMIS_URL } from "../../constants";
import { deepEqual } from "../../utils/deepEqual";
import { arrayReducer } from "../../utils/arrayReducer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const HFData = ({ params }) => {
  const admin = useSelector((state) => state.admin);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "TB Reporting",
      },
    },
  };
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  useEffect(() => {
    fetch(BASE_URL + "tbreportingrate/").then((res) =>
      res.json().then((result) => {
        let labels = [
          "TUBERCULOSIS_DATASET_REPORTING_RATE",
          "TUBERCULOSIS_OUTCOME_DATASET_REPORTING_RATE",
        ];
        const datasets = [
          {
            backgroundColor: ["#336699", "#99CCFF"],
            label: "TUBERCULOSIS_DATASET_REPORTING_RATE",
            data: labels.map((e) =>
              result.data
                .filter((f) => f.item_key === e)
                .reduce((prev, current) => {
                  return parseFloat(prev) + parseFloat(current.value);
                }, 0)
            ),
          },
        ];

        const data = {
          labels,
          datasets,
        };
        setChartData(data);
      })
    );

    return () => {};
  }, [admin.districts, admin.local_levels, admin.provinces, params]);

  return <Pie options={options} data={chartData} />;
};

function areEqual(prevProps, nextProps) {
  return deepEqual(prevProps, nextProps);
}

export default React.memo(HFData, areEqual);
