import React from "react";
import "./index.css";

function DeleteModal({ onDelete, state, setState }) {
  return (
    <>
      <div id="demo-modal" class="modal">
        <div class="modal__content">
          <h6>Are you sure you want to delete this data?</h6>

          {/* <span c style={{ marginRight: 20 }}>Yes</span> */}
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => {
              onDelete();
            }}
          >
            Yes
          </button>
          <button
            type="button"
            class="btn btn-light"
            onClick={() => {
              setState({
                ...state,
                deleteModal: {
                  opened: false,
                  id: null,
                },
              });
            }}
          >
            No
          </button>
        </div>
      </div>
    </>
  );
}

export default DeleteModal;
