import React, { useEffect } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../../constants";
import {
  setDistrict,
  setLocalLevel,
  setProvince,
  setDistrictIds,
  setLocalLevelIds,
  setProvinceIds,
  setProvinceList,
  setDistrictList,
  setLocalLevelList,
  setFiscalYears,
} from "../../slices/adminvalues";

export default function BufferTopbar() {
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  useEffect(() => {
    fetch(BASE_URL + "province/").then((res) => {
      res.json().then((result) => dispatch(setProvinceList(result)));
    });
    fetch(BASE_URL + "fiscalyears/").then((res) => {
      res.json().then((result) => dispatch(setFiscalYears(result)));
    });
    return () => {};
  }, [dispatch]);

  function getDistricts(provinceId) {
    if (!provinceId.length) {
      dispatch(setDistrictList([]));
      return;
    }
    fetch(BASE_URL + `district/?province_ids=${provinceId}`).then((res) => {
      res.json().then((result) => dispatch(setDistrictList(result)));
    });
  }
  function getLocalLevel(districtId) {
    if (!districtId.length) {
      dispatch(setLocalLevelList([]));
      return;
    }
    fetch(BASE_URL + `locallevel/?district_ids=${districtId}`).then((res) => {
      res.json().then((result) => dispatch(setLocalLevelList(result)));
    });
  }
  function handleProvinceChange(values) {
    const idsList = values.map((e) => e.value);
    dispatch(setProvinceIds(idsList));
    if (idsList.length) {
      dispatch(setProvince(idsList.at(-1)));
    } else {
      dispatch(setProvince(null));
    }
    getDistricts(idsList);
  }
  function handleDistrictChange(values) {
    const idsList = values.map((e) => e.value);
    dispatch(setDistrictIds(idsList));
    if (idsList.length) {
      dispatch(setDistrict(idsList.at(-1)));
    } else {
      dispatch(setDistrict(null));
    }
    getLocalLevel(idsList);
  }
  function handleLocalLevelChange(values) {
    const idsList = values.map((e) => e.value);
    if (idsList.length) {
      dispatch(setLocalLevel(idsList.at(-1)));
    } else {
      dispatch(setLocalLevel(null));
    }
    dispatch(setLocalLevelIds(idsList));
  }
  return (
    <>
      <div className="row gy-0 gx-0">
        <div className="col-md-3">
          <Select
            placeholder="Select Province"
            isMulti
            onChange={handleProvinceChange}
            options={admin.provinces.map((e) => {
              return { value: e.id, label: e.name_en };
            })}
          />
        </div>
        <div className="col-md-3">
          <Select
            placeholder="Select District"
            isMulti
            onChange={handleDistrictChange}
            options={admin.districts.map((e) => {
              return { value: e.id, label: e.name_en };
            })}
          />
        </div>
        <div className="col-md-3">
          <Select
            placeholder="Select Local Level"
            isMulti
            onChange={handleLocalLevelChange}
            options={admin.local_levels.map((e) => {
              return { value: e.id, label: e.name_en };
            })}
          />
        </div>
      </div>
    </>
  );
}
