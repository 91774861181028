import { Control } from "ol/control";
export default class SourceControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};

    const button = document.createElement("div");
    button.innerHTML = options.sourceText ?? "Attribution";

    const element = document.createElement("div");
    element.className = "data-source ol-unselectable ol-control";
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });
  }
}
