import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { BASE_URL } from "../../constants";
import { arrayReducer } from "../../utils/arrayReducer";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

export default function TBCases({ params }) {
  const admin = useSelector((state) => state.admin);
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "TB cases",
      },
    },
  };
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  useEffect(() => {
    fetch(BASE_URL + "tbcasesbyadmin/?" + new URLSearchParams(params)).then(
      (res) =>
        res.json().then((result) => {
          let labels = [];
          if (params.local_level_id != null) {
            labels = result.map((e) => `Ward ${e.hf_id__ward}`);
          } else if (params.district_id != null) {
            labels = result.map(
              (e) =>
                arrayReducer(admin.local_levels, "id")[
                  e.hf_id__local_level_id
                ].name_en.split(" ")[0]
            );
          } else if (params.province_id != null) {
            labels = result.map(
              (e) =>
                arrayReducer(admin.districts, "id")[e.hf_id__district_id]
                  .name_en
            );
          } else {
            labels = result.map(
              (e) =>
                arrayReducer(admin.provinces, "id")[e.hf_id__province_id]
                  ?.name_en
            );
          }
          const data = {
            labels,
            datasets: [
              {
                backgroundColor: "rgb(75, 192, 192)",
                label: "TB Cases",
                data: result.map((e) => e.count),
              },
            ],
          };
          setChartData(data);
        })
    );

    return () => {};
  }, [admin.districts, admin.local_levels, admin.provinces, params]);

  return <Bar options={options} data={chartData} />;
}
