import React, { useEffect, useState } from "react";
import TreeView from "react-expandable-treeview";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../constants";
import { setProvinceNode, setDistrictNode } from "../slices/orgstruct";

function OrganizationalStructure() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.orgstruct.data);
  useEffect(() => {
    fetch(BASE_URL + "orgstruct/").then((res) => {
      res.json().then((result) => {
        dispatch(setProvinceNode(result));
      });
    });
  }, [dispatch]);
  return (
    <div className="p-5 m-5">
      <h4 className="pb-4">Organizational Structure Tree</h4>
      {data.length > 0 ? (
        <TreeView
          data={data}
          renderNode={({ name_en, sdc_name, apiid, ntp_id }) => {
            return (
              <div>
                {name_en ?? sdc_name} ( {apiid ?? ntp_id} )
              </div>
            );
          }}
        />
      ) : (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
}

export default OrganizationalStructure;
