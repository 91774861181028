import React from "react";
import Sidebar from "../components/Sidebar";

function addSideBar(child) {
  return (
    <>
      <div className="col-md-2">
        <Sidebar />
      </div>
      <div className="col-md-10 h-100 d-flex flex-column">{child}</div>
    </>
  );
}

export default addSideBar;
