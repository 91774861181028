import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  routeNumber: 0,
  selectedHfTypes: [],
  graphhopperRoute: {
    paths: [
      {
        points: null,
        distance: 0,
        time: 0,
        instructions: [],
      },
    ],
  },
};

const routeValues = createSlice({
  name: "route",
  initialState,
  reducers: {
    setRouteNumber(state) {
      state.routeNumber += 1;
    },
    resetRoute(state) {
      state.graphhopperRoute = {
        paths: [
          {
            points: null,
            distance: 0,
            time: 0,
            instructions: [],
          },
        ],
      };
    },
    setRoute(state, { payload }) {
      state.graphhopperRoute = payload;
    },
  },
});

export const { setRoute, setRouteNumber, resetRoute } = routeValues.actions;

export default routeValues.reducer;
