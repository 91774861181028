import { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDistrictIds,
  setLocalLevelIds,
  setProvinceIds,
} from "../../../slices/adminvalues";
import "ol/ol.css";
import VectorSource from "ol/source/Vector";
import ClusterSource from "ol/source/Cluster";
import AnimatedClusterLayer from "ol-ext/layer/AnimatedCluster";
import VectorLayer from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { Fill, Style, Icon, Text, Stroke } from "ol/style";
import MapContext from "../MapContext";
import dots from "../../../assets/icons/DOTS.png";
import drcenter from "../../../assets/icons/DRcenter.png";
import geneX from "../../../assets/icons/GeneX.png";
import lpa from "../../../assets/icons/LPA.png";
import mc from "../../../assets/icons/mc.png";
import hstl from "../../../assets/icons/Hstl.png";
import home from "../../../assets/icons/Home.png";
import subdr from "../../../assets/icons/SubDR.png";
import { boundingExtent } from "ol/extent";
import { BASE_URL } from "../../../constants";
import { transformExtent } from "ol/proj";
import { bbox } from "@turf/turf";

var padding = [50, 50, 30, 150];

function getIconStyle(properties, xAnchor, yAnchor) {
  let icon;
  if ("is_dr" in properties) {
    icon = new Icon({
      src: drcenter,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  } else if ("is_sdr" in properties) {
    icon = new Icon({
      src: subdr,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  } else if ("is_mc" in properties) {
    icon = new Icon({
      src: mc,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  } else if ("is_dots" in properties) {
    icon = new Icon({
      src: dots,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  } else if ("is_gene" in properties) {
    icon = new Icon({
      src: geneX,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  } else if ("is_cltr_lpa" in properties) {
    icon = new Icon({
      src: lpa,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  } else if ("is_hostel_home" in properties) {
    icon = new Icon({
      src: hstl,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  } else if ("is_home" in properties) {
    icon = new Icon({
      src: home,
      anchor: [xAnchor ?? 10, yAnchor ?? 10],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      scale: [0.9, 0.9],
    });
  }
  return new Style({
    image: icon,
  });
}
const vectorSource = new VectorSource({});
const clusterSource = new ClusterSource({
  distance: 0,
  source: vectorSource,
});
const clusterLayer = new AnimatedClusterLayer({
  id: "cluster-layer",
  zIndex: 9999,
  source: clusterSource,
  style: (feature, resolution) => {
    var size = feature.get("features")?.length;
    if (size && size > 1) {
      const features = feature.get("features");
      return features.map((f, i) => {
        const properties = f.getProperties();
        return getIconStyle(properties, 20 * i, 20 * i);
      });
    }
    const properties = feature.get("features")[0].getProperties();
    return getIconStyle(properties);
  },
});
const provinceSource = new VectorSource({});
const provinceLayer = new VectorLayer({
  id: "province-layer",
  source: provinceSource,
  zIndex: 111,
  style: (feature, resolution) =>
    new Style({
      text: new Text({
        text: feature.getProperties()["name_en"],
        fillColor: "#fff",
        font: '20px "Lucida Grande",Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif',
      }),
      stroke: new Stroke({
        color: "#000000",
        width: 1.5,
      }),
      // fill: new Fill({
      //   color: stringToColour(feature.getProperties()["name_en"]),
      // }),
      fill: new Fill({
        color: "#fff",
      }),
    }),
});
const districtSource = new VectorSource({});
const districtLayer = new VectorLayer({
  id: "district-layer",
  source: districtSource,
  zIndex: 222,
  style: (feature, resolution) =>
    new Style({
      text: new Text({
        text: feature.getProperties()["name_en"],
        fillColor: "#fff",
        font: '16px "Lucida Grande",Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif',
      }),
      stroke: new Stroke({
        color: "#000000",
        width: 1,
      }),
      // fill: new Fill({
      //   color: stringToColour(feature.getProperties()["name_en"]),
      // }),
      fill: new Fill({
        color: "#fff",
      }),
    }),
});
const localLevelSource = new VectorSource({});
const localLevelLayer = new VectorLayer({
  id: "local-level-layer",
  source: localLevelSource,
  zIndex: 222,
  style: (feature, resolution) =>
    new Style({
      text: new Text({
        text: feature.getProperties()["name_en"],
        fillColor: "#fff",
        font: '10px "Lucida Grande",Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif',
      }),
      stroke: new Stroke({
        color: "#000000",
        width: 0.5,
      }),
      // fill: new Fill({
      //   color: stringToColour(feature.getProperties()["name_en"]),
      // }),
      fill: new Fill({
        color: "#fff",
      }),
    }),
});
export default function SDPLayerForExport() {
  const dispatch = useDispatch();
  const features = useSelector((state) => state.admin.hfs_filtered);
  const layerVisibility = useSelector((state) => state.layer);
  const filter = useSelector((state) => state.admin.adminFilter);
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;
    map.addLayer(clusterLayer);
    map.addLayer(provinceLayer);
    map.addLayer(districtLayer);
    map.addLayer(localLevelLayer);
    return () => {
      map.removeLayer(clusterLayer);
      map.removeLayer(provinceLayer);
      map.removeLayer(districtLayer);
      map.removeLayer(localLevelLayer);
    };
  }, [map]);

  useEffect(() => {
    if (!map) return;

    map.on("singleclick", (e) => {
      const { pixel } = e;
      clusterLayer.getFeatures(pixel).then((clickedFeatures) => {
        console.log(clickedFeatures);
        const allPointFeatures = map
          .getFeaturesAtPixel(pixel)
          .filter((e) => e.get("features")?.length);
        console.log(allPointFeatures, 1);
        if (clickedFeatures.length) {
          const features = clickedFeatures[0].get("features");
          if (features.length === 1) {
            const properties = features[0].getProperties();
            console.log(properties);
          } else if (features.length > 1) {
            const extent = boundingExtent(
              features.map((r) => r.getGeometry().getCoordinates())
            );
            if (extent[0] === extent[2] && extent[1] === extent[3]) {
              return;
            } else {
              map.getView().fit(extent, {
                size: map.getSize(),
                padding,
              });
            }
          }
        } else if (allPointFeatures.length) {
          console.log(allPointFeatures, 2);
        } else {
          localLevelLayer.getFeatures(pixel).then((clickedFeatures) => {
            if (clickedFeatures.length) {
              const local_level_id = clickedFeatures[0].getProperties()["id"];
              dispatch(setLocalLevelIds([local_level_id]));
            } else {
              districtLayer.getFeatures(pixel).then((clickedFeatures) => {
                if (clickedFeatures.length) {
                  const district_id = clickedFeatures[0].getProperties()["id"];
                  dispatch(setDistrictIds([district_id]));
                } else {
                  provinceLayer.getFeatures(pixel).then((clickedFeatures) => {
                    if (clickedFeatures.length) {
                      const province_id =
                        clickedFeatures[0].getProperties()["id"];
                      dispatch(setProvinceIds([province_id]));
                    }
                  });
                }
              });
            }
          });
        }
      });
    });

    return () => {};
  }, [dispatch, map]);

  useEffect(() => {
    provinceLayer.setVisible(layerVisibility.provincesVisible);
  }, [layerVisibility.provincesVisible]);
  useEffect(() => {
    districtLayer.setVisible(layerVisibility.districtsVisible);
  }, [layerVisibility.districtsVisible]);
  useEffect(() => {
    localLevelLayer.setVisible(layerVisibility.localLevelVisible);
  }, [layerVisibility.localLevelVisible]);
  useEffect(() => {
    if (!map) return;

    if (filter.local_level_ids.length)
      fetch(
        `${BASE_URL}locallevelgeojson/?locallevel_ids=${filter.local_level_ids}`
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          localLevelSource.clear();
          localLevelSource.addFeatures(
            new GeoJSON({
              featureProjection: "EPSG:3857",
            }).readFeatures(json)
          );
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    else if (filter.district_ids.length)
      fetch(`${BASE_URL}locallevelgeojson/?district_ids=${filter.district_ids}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          localLevelSource.clear();
          localLevelSource.addFeatures(
            new GeoJSON({
              featureProjection: "EPSG:3857",
            }).readFeatures(json)
          );
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    else if (filter.province_ids.length)
      fetch(`${BASE_URL}districtgeojson/?province_ids=${filter.province_ids}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          districtSource.clear();
          localLevelSource.clear();
          districtSource.addFeatures(
            new GeoJSON({
              featureProjection: "EPSG:3857",
            }).readFeatures(json)
          );
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    else
      fetch(`${BASE_URL}provincegeojson/`)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          localLevelSource.clear();
          districtSource.clear();
          provinceSource.clear();
          provinceSource.addFeatures(
            new GeoJSON({
              featureProjection: "EPSG:3857",
            }).readFeatures(json)
          );
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    return () => {};
  }, [map, filter.local_level_ids, filter.district_ids, filter.province_ids]);

  useEffect(() => {
    let selectedFeatures = [];
    if (layerVisibility.drVisible)
      selectedFeatures = [...selectedFeatures, ...(features?.dr || [])];
    if (layerVisibility.sdrVisible)
      selectedFeatures = [...selectedFeatures, ...(features?.sdr || [])];
    if (layerVisibility.mcVisible)
      selectedFeatures = [...selectedFeatures, ...(features?.mc || [])];
    if (layerVisibility.dotsVisible)
      selectedFeatures = [...selectedFeatures, ...(features?.dots || [])];
    if (layerVisibility.hstlVisible)
      selectedFeatures = [...selectedFeatures, ...(features?.hstl || [])];
    if (layerVisibility.homeVisible)
      selectedFeatures = [...selectedFeatures, ...(features?.home || [])];
    if (layerVisibility.cltrVisible)
      selectedFeatures = [...selectedFeatures, ...(features?.cltr || [])];
    if (layerVisibility.geneVisible)
      selectedFeatures = [...selectedFeatures, ...(features?.gene || [])];
    vectorSource.clear();
    vectorSource.addFeatures(
      new GeoJSON({
        featureProjection: "EPSG:3857",
      }).readFeatures({
        type: "FeatureCollection",
        features: selectedFeatures,
      })
    );
    return () => {};
  }, [
    features.cltr,
    features.dots,
    features.dr,
    features.gene,
    features.home,
    features.hstl,
    features.mc,
    features.sdr,
    layerVisibility.cltrVisible,
    layerVisibility.dotsVisible,
    layerVisibility.drVisible,
    layerVisibility.geneVisible,
    layerVisibility.homeVisible,
    layerVisibility.hstlVisible,
    layerVisibility.mcVisible,
    layerVisibility.sdrVisible,
  ]);
  return null;
}
