import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../constants";

function Login() {
  const [state, setState] = useState({
    username: "",
    password: "",
  });

  const navigate = useNavigate();

  const onsubmit = (e) => {
    e.preventDefault();
    console.log(state);

    fetch(BASE_URL + `token/`, {
      method: "POST",
      body: JSON.stringify({
        username: state.username,
        password: state.password,
      }),

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Token 1aa299933152694b017d3535a307a4659aa3801b",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "resp");
        if (res.token) {
          localStorage.setItem("token", res.token);
          window.dispatchEvent(new Event("storage"));
          navigate("/");

        } else {
          alert(Object.values(res).toString());
        }
      });
  };
  return (
    <div className="p-3" style={{ maxWidth: 500 }}>
      <form onSubmit={onsubmit}>
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            className="form-control"
            aria-describedby="emailHelp"
            placeholder="Enter username"
            onChange={(e) => {
              setState({ ...state, username: e.target.value });
            }}
          />
        </div>
        <div className="form-group">
          <label for="exampleInputPassword1">Password</label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Password"
            onChange={(e) => {
              setState({ ...state, password: e.target.value });
            }}
          />
        </div>

        <button type="submit" className="btn btn-primary mt-3">
          Login
        </button>
      </form>
    </div>
  );
}

export default Login;
