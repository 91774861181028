import { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDistrictIds,
  setLocalLevelIds,
  setProvinceIds,
} from "../../../slices/adminvalues";
import "ol/ol.css";
import Overlay from "ol/Overlay";
import VectorTileSource from "ol/source/VectorTile";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import MVT from "ol/format/MVT";
import GeoJSON from "ol/format/GeoJSON";
import { Fill, Style, Stroke, Text } from "ol/style";
import MapContext from "../MapContext";
import { BASE_URL } from "../../../constants";
import { transformExtent } from "ol/proj";
import { bbox } from "@turf/turf";
import chroma from "chroma-js";
import SourceControl from "../Controls/SourceControl";

const sourceControl = new SourceControl({
  sourceText: "Data Source HMIS",
});
export const provinceCategories = [
  [0, 60],
  [60, 70],
  [70, 80],
  [80, 90],
  [90, 100],
  [100, Infinity],
];
export const districtCategories = [
  [0, 60],
  [60, 70],
  [70, 80],
  [80, 90],
  [90, 100],
  [100, Infinity],
];
export const localLevelCategories = [
  [0, 60],
  [60, 70],
  [70, 80],
  [80, 90],
  [90, 100],
  [100, Infinity],
];
const provincecolorramp = chroma
  .scale(["#ffffff", "#fafa00"])
  .mode("lch")
  .colors(provinceCategories.length);
const districtcolorramp = chroma
  .scale(["#ffffff", "#00fafa"])
  .mode("lch")
  .colors(districtCategories.length);
const locallevelcolorramp = chroma
  .scale(["#ffffff", "#fa00fa"])
  .mode("lch")
  .colors(localLevelCategories.length);

export function provinceStyleFunction(feature, resolution) {
  const sum = feature.getProperties()["notification_rate"];
  let index;
  provinceCategories.forEach((e, i) => {
    if (sum >= e[0] && sum < e[1]) {
      index = i;
      return;
    }
  });
  return new Style({
    stroke: new Stroke({
      color: "#000000",
      width: 1.5,
    }),
    fill: new Fill({
      color: sum !== undefined ? provincecolorramp[index] : "#CCCCCC",
    }),
  });
}
export function districtStyleFunction(feature, resolution) {
  const sum = feature.getProperties()["notification_rate"];
  let index;
  districtCategories.forEach((e, i) => {
    if (sum >= e[0] && sum < e[1]) {
      index = i;
      return;
    }
  });
  return new Style({
    stroke: new Stroke({
      color: "#000000",
      width: 1,
    }),
    fill: new Fill({
      color: sum !== undefined ? districtcolorramp[index] : "#CCCCCC",
    }),
  });
}
export function localLevelStyleFunction(feature, resolution) {
  const sum = feature.getProperties()["notification_rate"];
  let index;
  localLevelCategories.forEach((e, i) => {
    if (sum >= e[0] && sum < e[1]) {
      index = i;
      return;
    }
  });
  return new Style({
    stroke: new Stroke({
      color: "#000000",
      width: 0.5,
    }),
    fill: new Fill({
      color: sum !== undefined ? locallevelcolorramp[index] : "#CCCCCC",
    }),
  });
}
const provinceSource = new VectorTileSource({
  projection: "EPSG:3857",
  maxZoom: 288,
  format: new MVT({}),
});
const provinceLayer = new VectorTileLayer({
  renderMode: "vector",
  className: "province-layer",
  source: provinceSource,
  zIndex: 1111,
  style: provinceStyleFunction,
});
const provinceLabelSource = new VectorSource({});
const provinceLabelLayer = new VectorLayer({
  declutter: true,
  className: "province-label-layer",
  source: provinceLabelSource,
  zIndex: 1112,
  style: (feature, resolution) => {
    return new Style({
      text: new Text({
        text: feature.getProperties()["name_en"],
        fillColor: "#fff",
        font: '20px "Lucida Grande",Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif',
      }),
    });
  },
});
const districtSource = new VectorTileSource({
  projection: "EPSG:3857",
  maxZoom: 288,
  format: new MVT({}),
});
const districtLayer = new VectorTileLayer({
  className: "district-layer",
  source: districtSource,
  zIndex: 2222,
  style: districtStyleFunction,
});
const districtLabelSource = new VectorSource({});
const districtLabelLayer = new VectorLayer({
  declutter: true,
  className: "district-label-layer",
  source: districtLabelSource,
  zIndex: 2223,
  style: (feature, resolution) =>
    new Style({
      text: new Text({
        text: feature.getProperties()["name_en"],
        fillColor: "#fff",
        font: '15px "Lucida Grande",Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif',
      }),
    }),
});
const localLevelSource = new VectorTileSource({
  projection: "EPSG:3857",
  maxZoom: 288,
  format: new MVT({}),
});
const localLevelLayer = new VectorTileLayer({
  className: "local-level-layer",
  source: localLevelSource,
  zIndex: 3333,
  style: localLevelStyleFunction,
});
const localLevelLabelSource = new VectorSource({});
const localLevelLabelLayer = new VectorLayer({
  declutter: true,
  className: "localLevel-label-layer",
  source: localLevelLabelSource,
  zIndex: 3334,
  style: (feature, resolution) =>
    new Style({
      text: new Text({
        text: feature.getProperties()["name_en"],
        fillColor: "#fff",
        font: '10px "Lucida Grande",Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif',
      }),
    }),
});

export default function TBNotificationRateLayer() {
  const dispatch = useDispatch();
  const layerVisibility = useSelector((state) => state.layer);
  const filter = useSelector((state) => state.admin.adminFilter);
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;
    map.addLayer(provinceLayer);
    map.addLayer(provinceLabelLayer);

    map.addLayer(districtLayer);
    map.addLayer(districtLabelLayer);

    map.addLayer(localLevelLayer);
    map.addLayer(localLevelLabelLayer);
    map.addControl(sourceControl);

    return () => {
      map.removeLayer(provinceLayer);
      map.removeLayer(districtLayer);
      map.removeLayer(localLevelLayer);
      map.removeLayer(provinceLabelLayer);
      map.removeLayer(districtLabelLayer);
      map.removeLayer(localLevelLabelLayer);
      map.removeControl(sourceControl);
    };
  }, [map]);
  useEffect(() => {
    if (!map) return;
    if (filter.local_level_ids.length) {
      fetch(
        `${BASE_URL}locallevelextent/?local_level_ids=${filter.local_level_ids}`
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    } else if (filter.district_ids.length) {
      fetch(`${BASE_URL}districtextent/?district_ids=${filter.district_ids}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    } else if (filter.province_ids.length) {
      fetch(`${BASE_URL}provinceextent/?province_ids=${filter.province_ids}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    } else {
      fetch(`${BASE_URL}provinceextent/`)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const extent = transformExtent(bbox(json), "EPSG:4326", "EPSG:3857");
          map.getView().fit(extent, map.getSize());
        });
    }
  }, [filter.local_level_ids, filter.district_ids, filter.province_ids, map]);

  useEffect(() => {
    if (!map) return;
    const container = document.getElementById("popup");
    const content = document.getElementById("popup-content");
    let feature = null;

    const overlay = new Overlay({
      stopEvent: true,
      element: container,
      autoPan: false,
      autoPanAnimation: {
        duration: 250,
      },
    });
    map.addOverlay(overlay);

    map.on("singleclick", (e) => {
      const { pixel } = e;
      localLevelLayer.getFeatures(pixel).then((clickedFeatures) => {
        if (clickedFeatures.length) {
          const local_level_id = clickedFeatures[0].getProperties()["id"];
          dispatch(setLocalLevelIds([local_level_id]));
        } else {
          districtLayer.getFeatures(pixel).then((clickedFeatures) => {
            if (clickedFeatures.length) {
              const district_id = clickedFeatures[0].getProperties()["id"];
              dispatch(setDistrictIds([district_id]));
            } else {
              provinceLayer.getFeatures(pixel).then((clickedFeatures) => {
                if (clickedFeatures.length) {
                  const province_id = clickedFeatures[0].getProperties()["id"];
                  dispatch(setProvinceIds([province_id]));
                }
              });
            }
          });
        }
      });
    });

    map.on("pointermove", (e) => {
      const { pixel, coordinate } = e;
      localLevelLayer.getFeatures(pixel).then((clickedFeatures) => {
        if (clickedFeatures.length) {
          const local_level_sum =
            clickedFeatures[0].getProperties()["notification_rate"] ?? "NA";
          const name = clickedFeatures[0].getProperties()["name_en"];
          content.innerHTML = `
                  <table style="width:100%;border-collapse:unset;">
                  <tr>
                      <td>Name: </td>
                      <td><b>${name}</b></td>
                    </tr>
                    <tr>
                      <td>Notification Rate: </td>
                      <td><b>${local_level_sum}</b></td>
                    </tr>
                  </table>
                  `;
          if (feature !== clickedFeatures[0]) {
            overlay.setPosition(coordinate);
            feature = clickedFeatures[0];
          }
        } else {
          districtLayer.getFeatures(pixel).then((clickedFeatures) => {
            if (clickedFeatures.length) {
              const district_sum =
                clickedFeatures[0].getProperties()["notification_rate"] ?? "NA";
              const name = clickedFeatures[0].getProperties()["name_en"];
              content.innerHTML = `
                  <table style="width:100%;border-collapse:unset;">
                  <tr>
                      <td>Name: </td>
                      <td><b>${name}</b></td>
                    </tr>
                    <tr>
                      <td>Notification Rate: </td>
                      <td><b>${district_sum}</b></td>
                    </tr>
                  </table>
                  `;
              if (feature !== clickedFeatures[0]) {
                overlay.setPosition(coordinate);
                feature = clickedFeatures[0];
              }
            } else {
              provinceLayer.getFeatures(pixel).then((clickedFeatures) => {
                if (clickedFeatures.length) {
                  const province_sum =
                    clickedFeatures[0].getProperties()["notification_rate"] ??
                    "NA";
                  const name = clickedFeatures[0].getProperties()["name_en"];
                  content.innerHTML = `
                  <table style="width:100%;border-collapse:unset;">
                    <tr>
                      <td>Name: </td>
                      <td><b>${name}</b></td>
                    </tr>
                    <tr>
                      <td>Notification Rate: </td>
                      <td><b>${province_sum}</b></td>
                    </tr>
                  </table>
                  `;
                  if (feature !== clickedFeatures[0]) {
                    overlay.setPosition(coordinate);
                    feature = clickedFeatures[0];
                  }
                } else {
                  overlay.setPosition(undefined);
                  feature = null;
                }
              });
            }
          });
        }
      });
    });

    return () => {
      map.removeOverlay(overlay);
    };
  }, [dispatch, map]);

  useEffect(() => {
    provinceLayer.setVisible(layerVisibility.provincesVisible);
    provinceLabelLayer.setVisible(layerVisibility.provincesVisible);
  }, [layerVisibility.provincesVisible]);
  useEffect(() => {
    districtLayer.setVisible(layerVisibility.districtsVisible);
    districtLabelLayer.setVisible(layerVisibility.districtsVisible);
  }, [layerVisibility.districtsVisible]);
  useEffect(() => {
    localLevelLayer.setVisible(layerVisibility.localLevelVisible);
    localLevelLabelLayer.setVisible(layerVisibility.localLevelVisible);
  }, [layerVisibility.localLevelVisible]);
  useEffect(() => {
    if (filter.fiscal_year_id) {
      provinceSource.clear();
      provinceSource.setUrl(
        `${BASE_URL}provincevt/{z}/{x}/{y}?fiscal_year=${filter.fiscal_year_id}`
      );
    }
  }, [filter.fiscal_year_id]);

  useEffect(() => {
    if (filter.fiscal_year_id) {
      districtSource.clear();
      districtSource.setUrl("");
      if (filter.province_ids.length) {
        districtSource.setUrl(
          `${BASE_URL}districtvt/{z}/{x}/{y}?fiscal_year=${filter.fiscal_year_id}&province_ids=${filter.province_ids}`
        );
      }
    }
  }, [filter.fiscal_year_id, filter.province_ids]);

  useEffect(() => {
    if (!map) return;
    if (filter.fiscal_year_id) {
      localLevelSource.clear();
      localLevelSource.setUrl("");

      if (filter.local_level_ids.length) {
        localLevelSource.setUrl(
          `${BASE_URL}locallevelvt/{z}/{x}/{y}?fiscal_year=${filter.fiscal_year_id}&locallevel_ids=${filter.local_level_ids}`
        );
      } else if (filter.district_ids.length) {
        localLevelSource.setUrl(
          `${BASE_URL}locallevelvt/{z}/{x}/{y}?fiscal_year=${filter.fiscal_year_id}&district_ids=${filter.district_ids}`
        );
      }
    }
    return () => {};
  }, [map, filter.fiscal_year_id, filter.local_level_ids, filter.district_ids]);
  useEffect(() => {
    fetch(`${BASE_URL}provincelabels/`)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        provinceLabelSource.addFeatures(
          new GeoJSON({
            featureProjection: "EPSG:3857",
          }).readFeatures(json)
        );
      });
  }, []);

  useEffect(() => {
    districtLabelSource.clear();
    if (filter.province_ids.length) {
      fetch(`${BASE_URL}districtlabels/?province_ids=${filter.province_ids}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          districtLabelSource.addFeatures(
            new GeoJSON({
              featureProjection: "EPSG:3857",
            }).readFeatures(json)
          );
        });
    }
  }, [filter.province_ids]);

  useEffect(() => {
    localLevelLabelSource.clear();

    if (filter.local_level_ids.length) {
      fetch(
        `${BASE_URL}locallevellabels/?local_level_ids=${filter.local_level_ids}`
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          localLevelLabelSource.addFeatures(
            new GeoJSON({
              featureProjection: "EPSG:3857",
            }).readFeatures(json)
          );
        });
    } else if (filter.district_ids.length) {
      fetch(`${BASE_URL}locallevellabels/?district_ids=${filter.district_ids}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          localLevelLabelSource.addFeatures(
            new GeoJSON({
              featureProjection: "EPSG:3857",
            }).readFeatures(json)
          );
        });
    }
  }, [map, filter.local_level_ids, filter.district_ids]);
  return (
    <div id="popup" className="ol-popup">
      <div id="popup-content" className="is-overflow" />
    </div>
  );
}
