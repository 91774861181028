import React from "react";
import { useSelector } from "react-redux";
import TBCases from "../components/Charts/TBCases";

function CasesChart() {
  const filter = useSelector((state) => state.admin.adminFilter);

  return (
    <div id="chart-column" className="col-md-12 p-5">
      <TBCases
        params={{
          province_id: filter.province_id,
          district_id: filter.district_id,
          local_level_id: filter.local_level_id,
        }}
      />
    </div>
  );
}

export default CasesChart;
