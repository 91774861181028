import { useEffect, useContext, useState } from "react";
import Vector from "ol/layer/Vector";
import sVector from "ol/source/Vector";
import { Style, Icon } from "ol/style";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import MapContext from "../MapContext";
import { fromLonLat, transform } from "ol/proj";
import mkr from "../../../assets/mrk.png";

export function InputLayer({ onChange, defaultMarker }) {
  const { map } = useContext(MapContext);

  const [markers, setMarkers] = useState(
    new Vector({
      source: new sVector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: mkr,
          scale: 0.01,
        }),
      }),
    })
  );
  const [state, setState] = useState({
    initialSetboundsDone: false,
  });

  useEffect(() => {
    if (!map) return;
    if (state.initialSetboundsDone) return;
    var marker = new Feature(new Point(fromLonLat(defaultMarker)));
    markers.getSource().clear();
    markers.getSource().addFeature(marker);
    map.getView().setCenter(transform(defaultMarker, "EPSG:4326", "EPSG:3857"));
    map.getView().setZoom(8);
    setState({ ...state, initialSetboundsDone: true });
  }, [defaultMarker, map, markers, state]);
  useEffect(() => {
    if (!map) return;

    map.on("click", function (event) {
      const lnglat = transform(event.coordinate, "EPSG:3857", "EPSG:4326");
      onChange(lnglat);
      var marker = new Feature(new Point(fromLonLat(lnglat)));
      markers.getSource().clear();
      markers.getSource().addFeature(marker);
    });

    setMarkers(markers);
  }, [map, markers, onChange]);

  useEffect(() => {
    if (!map) return;
    map.addLayer(markers);
  }, [map, markers]);
  return null;
}
