import { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import LegendControl from "ol-ext/control/Legend";
import OLLegend from "ol-ext/legend/Legend";
import MapContext from "../MapContext";
import {
  provinceStyleFunction,
  provinceCategories,
  districtCategories,
  districtStyleFunction,
  localLevelCategories,
  localLevelStyleFunction,
} from "../Layers/TBSuccessRateLayerVT";
import Feature from "ol/Feature";

function Legend() {
  const { map } = useContext(MapContext);

  const layerVisibility = useSelector((state) => state.layer);
  const filter = useSelector((state) => state.admin.adminFilter);
  useEffect(() => {
    if (!map) return;
    var legend = new OLLegend({
      title: "Legend",
      margin: 2,
      size: [16, 16],
    });
    var legendCtrl = new LegendControl({
      legend: legend,
    });
    if (layerVisibility.provincesVisible) {
      legend.addItem({
        title: "Province Legend",
      });
      provinceCategories.forEach((e) => {
        legend.addItem({
          title: `${e[0]}-${e[1]}`,
          style: provinceStyleFunction(new Feature({ success_rate: e[0] })),
          typeGeom: "Polygon",
        });
      });
    }
    if (layerVisibility.districtsVisible && filter.province_ids.length) {
      legend.addItem({
        title: "District Legend",
      });
      districtCategories.forEach((e) => {
        legend.addItem({
          title: `${e[0]}-${e[1]}`,
          style: districtStyleFunction(new Feature({ success_rate: e[0] })),
          typeGeom: "Polygon",
        });
      });
    }
    if (layerVisibility.localLevelVisible && filter.district_ids.length) {
      legend.addItem({
        title: "Local Level Legend",
      });
      localLevelCategories.forEach((e) => {
        legend.addItem({
          title: `${e[0]}-${e[1]}`,
          style: localLevelStyleFunction(new Feature({ success_rate: e[0] })),
          typeGeom: "Polygon",
        });
      });
    }
    map.addControl(legendCtrl);

    return () => {
      map.controls.remove(legendCtrl);
    };
  }, [
    filter.province_ids,
    filter.district_ids,
    layerVisibility.districtsVisible,
    layerVisibility.localLevelVisible,
    layerVisibility.provincesVisible,
    map,
  ]);

  return null;
}

export default Legend;
