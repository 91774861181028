import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import MapContext from "../MapContext";
import CanvasScaleLine from "ol-ext/control/CanvasScaleLine";
import ScaleLine from "ol/control/ScaleLine";
import { Fill, Style } from "ol/style";

function Scale() {
  const { map } = useContext(MapContext);
  const scaleSettings = useSelector((state) => state.mapSettings.scaleSettings);
  useEffect(() => {
    if (!map) return;
    let scaleControl = new CanvasScaleLine({});
    scaleControl.olscale = new ScaleLine({
      units: scaleSettings.units,
      bar: true,
      steps: scaleSettings.steps,
      text: scaleSettings.showScaleText,
      minWidth: 180,
      className: "my-scale",
    });
    map.controls.push(scaleControl);

    return () => map.controls.remove(scaleControl);
  }, [
    map,
    scaleSettings.showScaleText,
    scaleSettings.steps,
    scaleSettings.type,
    scaleSettings.units,
  ]);
  return null;
}

export default Scale;
